import React from 'react';
import {appGetUrlPath} from '../function_lib/common_cms';
import './feature_card.css';
export function Feature_Card(lsData){
   // alert(lsData);
    var appUrtPath=appGetUrlPath();
var listData=lsData;
var strResp="";
var strHeader="<div class=\"section feature_web_bg mb-50px pt-70px pb-100px\">"+
              "<div class=\"container\">"+
              "<div class=\"row\">";

var strFooter="</div></div></div>";
for(var i=0;i<listData.length;i++){
    strResp+="<div class=\"col-12 col-sm-6 col-lg-4 d-flex\"><div class=\"carditem\">"+
    "<div class=\"row1\">"+
        "<div class=\"imgtag\" style=\"background-image: url('./flat.jpg');\">"+
           "Flat 30% off"+
        "</div>"+
        "<div class=\"boxname\">"+
        lsData[i]["col1"]+
        "</div>"+
    "</div>"+
    "<div class=\"row2\">"+
        "<button class=\"button1\">"+
            "Order Now"+
        "</button>"+
    "</div>"+
    "<div class=\"sideimg\">"+        
    "<img style=\"max-width:100%;max-height:100vh\" src=\""+appUrtPath+lsData[i]["col4"]+"\" alt=\"medicine\">"+
    "</div>"+
"</div></div>";
}
//alert(strHeader+strResp+strFooter);
return strHeader+strResp+strFooter;
}
export default Feature_Card;