import React from 'react';
class Left_Pan_Page extends React.Component{
    render(){
        return(<div className="shop-category-area pb-100px pt-70px">
        <div className="container">
            <div className="row">
                <div className="col-lg-9 order-lg-last col-md-12 order-md-first">
                    
                    <div className="shop-top-bar d-flex">
                        
                        <p>There Are 17 Products.</p>
                       
                        <div className="select-shoing-wrap d-flex align-items-center">
                            <div className="shot-product">
                                <p>Sort By:</p>
                            </div>
                            <div className="shop-select">
                                <select className="shop-sort">
                                    <option data-display="Relevance">Relevance</option>
                                    <option value="1"> Name, A to Z</option>
                                    <option value="2"> Name, Z to A</option>
                                    <option value="3"> Price, low to high</option>
                                    <option value="4"> Price, high to low</option>
                                </select>

                            </div>
                        </div>
                       
                    </div>
                   

                    
                    <div className="shop-bottom-area">

                        <div className="row">
                            <div className="col-lg-4  col-md-6 col-sm-6 col-xs-6" data-aos="fade-up" data-aos-delay="200">
                                {/* <!-- Single Prodect --> */}
                                <div className="product mb-5">
                                    <div className="thumb">
                                        <a href="shop-left-sidebar.html" className="image">
                                            <img src="assets/images/product-image/1.jpg" alt="Product" />
                                            <img className="hover-image" src="assets/images/product-image/2.jpg" alt="Product" />
                                        </a>
                                        <span className="badges">
                                            <span className="new">New</span>
                                        </span>
                                        <div className="actions">
                                            <a href="wishlist.html" className="action wishlist" title="Wishlist"><i
                                                    className="icon-heart"></i></a>
                                            <a href="#" className="action quickview" data-link-action="quickview" title="Quick view" data-bs-toggle="modal" data-bs-target="#exampleModal"><i className="icon-size-fullscreen"></i></a>
                                            <a href="compare.html" className="action compare" title="Compare"><i
                                                    className="icon-refresh"></i></a>
                                        </div>
                                        <button title="Add To Cart" className=" add-to-cart">Add
                                            To Cart</button>
                                    </div>
                                    <div className="content">
                                        <h5 className="title"><a href="shop-left-sidebar.html">Simple minimal Chair</a></h5>
                                        <span className="price">
                                            <span className="new">$38.50</span>
                                        </span>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-4  col-md-6 col-sm-6 col-xs-6" data-aos="fade-up" data-aos-delay="400">
                                {/* <!-- Single Prodect --> */}
                                <div className="product mb-5">
                                    <div className="thumb">
                                        <a href="shop-left-sidebar.html" className="image">
                                            <img src="assets/images/product-image/3.jpg" alt="Product" />
                                            <img className="hover-image" src="assets/images/product-image/4.jpg" alt="Product" />
                                        </a>
                                        <span className="badges">
                                            <span className="sale">-7%</span>
                                        </span>
                                        <div className="actions">
                                            <a href="wishlist.html" className="action wishlist" title="Wishlist"><i
                                                    className="icon-heart"></i></a>
                                            <a href="#" className="action quickview" data-link-action="quickview" title="Quick view" data-bs-toggle="modal" data-bs-target="#exampleModal"><i className="icon-size-fullscreen"></i></a>
                                            <a href="compare.html" className="action compare" title="Compare"><i
                                                    className="icon-refresh"></i></a>
                                        </div>
                                        <button title="Add To Cart" className=" add-to-cart">Add
                                            To Cart</button>
                                    </div>
                                    <div className="content">
                                        <h5 className="title"><a href="shop-left-sidebar.html">Wooden decorations</a></h5>
                                        <span className="price">
                                            <span className="new">$30.50</span>
                                        <span className="old">$38.00</span>
                                        </span>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-4  col-md-6 col-sm-6 col-xs-6" data-aos="fade-up" data-aos-delay="600">
                                {/* <!-- Single Prodect --> */}
                                <div className="product mb-5">
                                    <div className="thumb">
                                        <a href="shop-left-sidebar.html" className="image">
                                            <img src="assets/images/product-image/5.jpg" alt="Product" />
                                            <img className="hover-image" src="assets/images/product-image/6.jpg" alt="Product" />
                                        </a>
                                        <span className="badges">
                                            <span className="sale">-5%</span>
                                        <span className="new">New</span>
                                        </span>
                                        <div className="actions">
                                            <a href="wishlist.html" className="action wishlist" title="Wishlist"><i
                                                    className="icon-heart"></i></a>
                                            <a href="#" className="action quickview" data-link-action="quickview" title="Quick view" data-bs-toggle="modal" data-bs-target="#exampleModal"><i className="icon-size-fullscreen"></i></a>
                                            <a href="compare.html" className="action compare" title="Compare"><i
                                                    className="icon-refresh"></i></a>
                                        </div>
                                        <button title="Add To Cart" className=" add-to-cart">Add
                                            To Cart</button>
                                    </div>
                                    <div className="content">
                                        <h5 className="title"><a href="shop-left-sidebar.html">High quality vase bottle</a></h5>
                                        <span className="price">
                                            <span className="new">$38.50</span>
                                        <span className="old">$40.50</span>
                                        </span>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-4  col-md-6 col-sm-6 col-xs-6" data-aos="fade-up" data-aos-delay="200">
                                {/* <!-- Single Prodect --> */}
                                <div className="product mb-5">
                                    <div className="thumb">
                                        <a href="shop-left-sidebar.html" className="image">
                                            <img src="assets/images/product-image/7.jpg" alt="Product" />
                                            <img className="hover-image" src="assets/images/product-image/8.jpg" alt="Product" />
                                        </a>
                                        <span className="badges">
                                        </span>
                                        <div className="actions">
                                            <a href="wishlist.html" className="action wishlist" title="Wishlist"><i
                                                    className="icon-heart"></i></a>
                                            <a href="#" className="action quickview" data-link-action="quickview" title="Quick view" data-bs-toggle="modal" data-bs-target="#exampleModal"><i className="icon-size-fullscreen"></i></a>
                                            <a href="compare.html" className="action compare" title="Compare"><i
                                                    className="icon-refresh"></i></a>
                                        </div>
                                        <button title="Add To Cart" className=" add-to-cart">Add
                                            To Cart</button>
                                    </div>
                                    <div className="content">
                                        <h5 className="title"><a href="shop-left-sidebar.html">Living & Bedroom Chair</a></h5>
                                        <span className="price">
                                            <span className="new">$30.50</span>
                                        </span>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-4  col-md-6 col-sm-6 col-xs-6" data-aos="fade-up" data-aos-delay="400">
                                {/* <!-- Single Prodect --> */}
                                <div className="product mb-5">
                                    <div className="thumb">
                                        <a href="shop-left-sidebar.html" className="image">
                                            <img src="assets/images/product-image/9.jpg" alt="Product" />
                                            <img className="hover-image" src="assets/images/product-image/10.jpg" alt="Product" />
                                        </a>
                                        <span className="badges">
                                            <span className="sale">-7%</span>
                                        </span>
                                        <div className="actions">
                                            <a href="wishlist.html" className="action wishlist" title="Wishlist"><i
                                                    className="icon-heart"></i></a>
                                            <a href="#" className="action quickview" data-link-action="quickview" title="Quick view" data-bs-toggle="modal" data-bs-target="#exampleModal"><i className="icon-size-fullscreen"></i></a>
                                            <a href="compare.html" className="action compare" title="Compare"><i
                                                    className="icon-refresh"></i></a>
                                        </div>
                                        <button title="Add To Cart" className=" add-to-cart">Add
                                            To Cart</button>
                                    </div>
                                    <div className="content">
                                        <h5 className="title"><a href="shop-left-sidebar.html">Living & Bedroom Table</a></h5>
                                        <span className="price">
                                            <span className="new">$30.50</span>
                                        <span className="old">$38.00</span>
                                        </span>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-4  col-md-6 col-sm-6 col-xs-6" data-aos="fade-up" data-aos-delay="600">
                                {/* <!-- Single Prodect --> */}
                                <div className="product mb-5">
                                    <div className="thumb">
                                        <a href="shop-left-sidebar.html" className="image">
                                            <img src="assets/images/product-image/11.jpg" alt="Product" />
                                            <img className="hover-image" src="assets/images/product-image/12.jpg" alt="Product" />
                                        </a>
                                        <span className="badges">
                                            <span className="new">New</span>
                                        </span>
                                        <div className="actions">
                                            <a href="wishlist.html" className="action wishlist" title="Wishlist"><i
                                                    className="icon-heart"></i></a>
                                            <a href="#" className="action quickview" data-link-action="quickview" title="Quick view" data-bs-toggle="modal" data-bs-target="#exampleModal"><i className="icon-size-fullscreen"></i></a>
                                            <a href="compare.html" className="action compare" title="Compare"><i
                                                    className="icon-refresh"></i></a>
                                        </div>
                                        <button title="Add To Cart" className=" add-to-cart">Add
                                            To Cart</button>
                                    </div>
                                    <div className="content">
                                        <h5 className="title"><a href="shop-left-sidebar.html">Wooden decorations</a></h5>
                                        <span className="price">
                                            <span className="new">$38.50</span>
                                        </span>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-4  col-md-6 col-sm-6 col-xs-6" data-aos="fade-up" data-aos-delay="200">
                                {/* <!-- Single Prodect --> */}
                                <div className="product mb-5">
                                    <div className="thumb">
                                        <a href="shop-left-sidebar.html" className="image">
                                            <img src="assets/images/product-image/13.jpg" alt="Product" />
                                            <img className="hover-image" src="assets/images/product-image/14.jpg" alt="Product" />
                                        </a>
                                        <span className="badges">
                                        </span>
                                        <div className="actions">
                                            <a href="wishlist.html" className="action wishlist" title="Wishlist"><i
                                                    className="icon-heart"></i></a>
                                            <a href="#" className="action quickview" data-link-action="quickview" title="Quick view" data-bs-toggle="modal" data-bs-target="#exampleModal"><i className="icon-size-fullscreen"></i></a>
                                            <a href="compare.html" className="action compare" title="Compare"><i
                                                    className="icon-refresh"></i></a>
                                        </div>
                                        <button title="Add To Cart" className=" add-to-cart">Add
                                            To Cart</button>
                                    </div>
                                    <div className="content">
                                        <h5 className="title"><a href="shop-left-sidebar.html">High quality vase bottle</a></h5>
                                        <span className="price">
                                            <span className="new">$30.50</span>
                                        </span>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-4  col-md-6 col-sm-6 col-xs-6" data-aos="fade-up" data-aos-delay="400">
                                {/* <!-- Single Prodect --> */}
                                <div className="product mb-5">
                                    <div className="thumb">
                                        <a href="shop-left-sidebar.html" className="image">
                                            <img src="assets/images/product-image/15.jpg" alt="Product" />
                                            <img className="hover-image" src="assets/images/product-image/16.jpg" alt="Product" />
                                        </a>
                                        <span className="badges">
                                            <span className="sale">-5%</span>
                                        <span className="new">New</span>
                                        </span>
                                        <div className="actions">
                                            <a href="wishlist.html" className="action wishlist" title="Wishlist"><i
                                                    className="icon-heart"></i></a>
                                            <a href="#" className="action quickview" data-link-action="quickview" title="Quick view" data-bs-toggle="modal" data-bs-target="#exampleModal"><i className="icon-size-fullscreen"></i></a>
                                            <a href="compare.html" className="action compare" title="Compare"><i
                                                    className="icon-refresh"></i></a>
                                        </div>
                                        <button title="Add To Cart" className=" add-to-cart">Add
                                            To Cart</button>
                                    </div>
                                    <div className="content">
                                        <h5 className="title"><a href="shop-left-sidebar.html">Living & Bedroom Chair</a></h5>
                                        <span className="price">
                                            <span className="new">$38.50</span>
                                        <span className="old">$40.50</span>
                                        </span>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-4  col-md-6 col-sm-6 col-xs-6" data-aos="fade-up" data-aos-delay="600">
                                {/* <!-- Single Prodect --> */}
                                <div className="product mb-5">
                                    <div className="thumb">
                                        <a href="shop-left-sidebar.html" className="image">
                                            <img src="assets/images/product-image/1.jpg" alt="Product" />
                                            <img className="hover-image" src="assets/images/product-image/2.jpg" alt="Product" />
                                        </a>
                                        <span className="badges">
                                            <span className="new">New</span>
                                        </span>
                                        <div className="actions">
                                            <a href="wishlist.html" className="action wishlist" title="Wishlist"><i
                                                    className="icon-heart"></i></a>
                                            <a href="#" className="action quickview" data-link-action="quickview" title="Quick view" data-bs-toggle="modal" data-bs-target="#exampleModal"><i className="icon-size-fullscreen"></i></a>
                                            <a href="compare.html" className="action compare" title="Compare"><i
                                                    className="icon-refresh"></i></a>
                                        </div>
                                        <button title="Add To Cart" className=" add-to-cart">Add
                                            To Cart</button>
                                    </div>
                                    <div className="content">
                                        <h5 className="title"><a href="shop-left-sidebar.html">Simple minimal Chair</a></h5>
                                        <span className="price">
                                            <span className="new">$38.50</span>
                                        </span>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-4  col-md-6 col-sm-6 col-xs-6" data-aos="fade-up" data-aos-delay="200">
                                {/* <!-- Single Prodect --> */}
                                <div className="product mb-5">
                                    <div className="thumb">
                                        <a href="shop-left-sidebar.html" className="image">
                                            <img src="assets/images/product-image/3.jpg" alt="Product" />
                                            <img className="hover-image" src="assets/images/product-image/4.jpg" alt="Product" />
                                        </a>
                                        <span className="badges">
                                            <span className="sale">-7%</span>
                                        </span>
                                        <div className="actions">
                                            <a href="wishlist.html" className="action wishlist" title="Wishlist"><i
                                                    className="icon-heart"></i></a>
                                            <a href="#" className="action quickview" data-link-action="quickview" title="Quick view" data-bs-toggle="modal" data-bs-target="#exampleModal"><i className="icon-size-fullscreen"></i></a>
                                            <a href="compare.html" className="action compare" title="Compare"><i
                                                    className="icon-refresh"></i></a>
                                        </div>
                                        <button title="Add To Cart" className=" add-to-cart">Add
                                            To Cart</button>
                                    </div>
                                    <div className="content">
                                        <h5 className="title"><a href="shop-left-sidebar.html">Wooden decorations</a></h5>
                                        <span className="price">
                                            <span className="new">$30.50</span>
                                        <span className="old">$38.00</span>
                                        </span>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-4  col-md-6 col-sm-6 col-xs-6" data-aos="fade-up" data-aos-delay="400">
                                {/* <!-- Single Prodect --> */}
                                <div className="product mb-5">
                                    <div className="thumb">
                                        <a href="shop-left-sidebar.html" className="image">
                                            <img src="assets/images/product-image/5.jpg" alt="Product" />
                                            <img className="hover-image" src="assets/images/product-image/6.jpg" alt="Product" />
                                        </a>
                                        <span className="badges">
                                            <span className="sale">-5%</span>
                                        <span className="new">New</span>
                                        </span>
                                        <div className="actions">
                                            <a href="wishlist.html" className="action wishlist" title="Wishlist"><i
                                                    className="icon-heart"></i></a>
                                            <a href="#" className="action quickview" data-link-action="quickview" title="Quick view" data-bs-toggle="modal" data-bs-target="#exampleModal"><i className="icon-size-fullscreen"></i></a>
                                            <a href="compare.html" className="action compare" title="Compare"><i
                                                    className="icon-refresh"></i></a>
                                        </div>
                                        <button title="Add To Cart" className=" add-to-cart">Add
                                            To Cart</button>
                                    </div>
                                    <div className="content">
                                        <h5 className="title"><a href="shop-left-sidebar.html">High quality vase bottle</a></h5>
                                        <span className="price">
                                            <span className="new">$38.50</span>
                                        <span className="old">$40.50</span>
                                        </span>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-4  col-md-6 col-sm-6 col-xs-6" data-aos="fade-up" data-aos-delay="600">
                                {/* <!-- Single Prodect --> */}
                                <div className="product mb-5">
                                    <div className="thumb">
                                        <a href="shop-left-sidebar.html" className="image">
                                            <img src="assets/images/product-image/7.jpg" alt="Product" />
                                            <img className="hover-image" src="assets/images/product-image/8.jpg" alt="Product" />
                                        </a>
                                        <span className="badges">
                                        </span>
                                        <div className="actions">
                                            <a href="wishlist.html" className="action wishlist" title="Wishlist"><i
                                                    className="icon-heart"></i></a>
                                            <a href="#" className="action quickview" data-link-action="quickview" title="Quick view" data-bs-toggle="modal" data-bs-target="#exampleModal"><i className="icon-size-fullscreen"></i></a>
                                            <a href="compare.html" className="action compare" title="Compare"><i
                                                    className="icon-refresh"></i></a>
                                        </div>
                                        <button title="Add To Cart" className=" add-to-cart">Add
                                            To Cart</button>
                                    </div>
                                    <div className="content">
                                        <h5 className="title"><a href="shop-left-sidebar.html">Living & Bedroom Chair</a></h5>
                                        <span className="price">
                                            <span className="new">$30.50</span>
                                        </span>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-4  col-md-6 col-sm-6 col-xs-6" data-aos="fade-up" data-aos-delay="200">
                                {/* <!-- Single Prodect --> */}
                                <div className="product mb-5">
                                    <div className="thumb">
                                        <a href="shop-left-sidebar.html" className="image">
                                            <img src="assets/images/product-image/9.jpg" alt="Product" />
                                            <img className="hover-image" src="assets/images/product-image/10.jpg" alt="Product" />
                                        </a>
                                        <span className="badges">
                                            <span className="sale">-7%</span>
                                        </span>
                                        <div className="actions">
                                            <a href="wishlist.html" className="action wishlist" title="Wishlist"><i
                                                    className="icon-heart"></i></a>
                                            <a href="#" className="action quickview" data-link-action="quickview" title="Quick view" data-bs-toggle="modal" data-bs-target="#exampleModal"><i className="icon-size-fullscreen"></i></a>
                                            <a href="compare.html" className="action compare" title="Compare"><i
                                                    className="icon-refresh"></i></a>
                                        </div>
                                        <button title="Add To Cart" className=" add-to-cart">Add
                                            To Cart</button>
                                    </div>
                                    <div className="content">
                                        <h5 className="title"><a href="shop-left-sidebar.html">Living & Bedroom Table</a></h5>
                                        <span className="price">
                                            <span className="new">$30.50</span>
                                        <span className="old">$38.00</span>
                                        </span>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-4  col-md-6 col-sm-6 col-xs-6" data-aos="fade-up" data-aos-delay="400">
                                {/* <!-- Single Prodect --> */}
                                <div className="product mb-5">
                                    <div className="thumb">
                                        <a href="shop-left-sidebar.html" className="image">
                                            <img src="assets/images/product-image/11.jpg" alt="Product" />
                                            <img className="hover-image" src="assets/images/product-image/12.jpg" alt="Product" />
                                        </a>
                                        <span className="badges">
                                            <span className="new">New</span>
                                        </span>
                                        <div className="actions">
                                            <a href="wishlist.html" className="action wishlist" title="Wishlist"><i
                                                    className="icon-heart"></i></a>
                                            <a href="#" className="action quickview" data-link-action="quickview" title="Quick view" data-bs-toggle="modal" data-bs-target="#exampleModal"><i className="icon-size-fullscreen"></i></a>
                                            <a href="compare.html" className="action compare" title="Compare"><i
                                                    className="icon-refresh"></i></a>
                                        </div>
                                        <button title="Add To Cart" className=" add-to-cart">Add
                                            To Cart</button>
                                    </div>
                                    <div className="content">
                                        <h5 className="title"><a href="shop-left-sidebar.html">Wooden decorations</a></h5>
                                        <span className="price">
                                            <span className="new">$38.50</span>
                                        </span>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-4  col-md-6 col-sm-6 col-xs-6" data-aos="fade-up" data-aos-delay="600">
                                {/* <!-- Single Prodect --> */}
                                <div className="product mb-5">
                                    <div className="thumb">
                                        <a href="shop-left-sidebar.html" className="image">
                                            <img src="assets/images/product-image/13.jpg" alt="Product" />
                                            <img className="hover-image" src="assets/images/product-image/14.jpg" alt="Product" />
                                        </a>
                                        <span className="badges">
                                        </span>
                                        <div className="actions">
                                            <a href="wishlist.html" className="action wishlist" title="Wishlist"><i
                                                    className="icon-heart"></i></a>
                                            <a href="#" className="action quickview" data-link-action="quickview" title="Quick view" data-bs-toggle="modal" data-bs-target="#exampleModal"><i className="icon-size-fullscreen"></i></a>
                                            <a href="compare.html" className="action compare" title="Compare"><i
                                                    className="icon-refresh"></i></a>
                                        </div>
                                        <button title="Add To Cart" className=" add-to-cart">Add
                                            To Cart</button>
                                    </div>
                                    <div className="content">
                                        <h5 className="title"><a href="shop-left-sidebar.html">High quality vase bottle</a></h5>
                                        <span className="price">
                                            <span className="new">$30.50</span>
                                        </span>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-4  col-md-6 col-sm-6 col-xs-6" data-aos="fade-up" data-aos-delay="200">
                                {/* <!-- Single Prodect --> */}
                                <div className="product mb-5">
                                    <div className="thumb">
                                        <a href="shop-left-sidebar.html" className="image">
                                            <img src="assets/images/product-image/15.jpg" alt="Product" />
                                            <img className="hover-image" src="assets/images/product-image/16.jpg" alt="Product" />
                                        </a>
                                        <span className="badges">
                                            <span className="sale">-5%</span>
                                        <span className="new">New</span>
                                        </span>
                                        <div className="actions">
                                            <a href="wishlist.html" className="action wishlist" title="Wishlist"><i
                                                    className="icon-heart"></i></a>
                                            <a href="#" className="action quickview" data-link-action="quickview" title="Quick view" data-bs-toggle="modal" data-bs-target="#exampleModal"><i className="icon-size-fullscreen"></i></a>
                                            <a href="compare.html" className="action compare" title="Compare"><i
                                                    className="icon-refresh"></i></a>
                                        </div>
                                        <button title="Add To Cart" className=" add-to-cart">Add
                                            To Cart</button>
                                    </div>
                                    <div className="content">
                                        <h5 className="title"><a href="shop-left-sidebar.html">Living & Bedroom Chair</a></h5>
                                        <span className="price">
                                            <span className="new">$38.50</span>
                                        <span className="old">$40.50</span>
                                        </span>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-4  col-md-6 col-sm-6 col-xs-6" data-aos="fade-up" data-aos-delay="400">
                                {/* <!-- Single Prodect --> */}
                                <div className="product mb-5">
                                    <div className="thumb">
                                        <a href="shop-left-sidebar.html" className="image">
                                            <img src="assets/images/product-image/1.jpg" alt="Product" />
                                            <img className="hover-image" src="assets/images/product-image/2.jpg" alt="Product" />
                                        </a>
                                        <span className="badges">
                                        <span className="new">New</span>
                                        </span>
                                        <div className="actions">
                                            <a href="wishlist.html" className="action wishlist" title="Wishlist"><i
                                                    className="icon-heart"></i></a>
                                            <a href="#" className="action quickview" data-link-action="quickview" title="Quick view" data-bs-toggle="modal" data-bs-target="#exampleModal"><i className="icon-size-fullscreen"></i></a>
                                            <a href="compare.html" className="action compare" title="Compare"><i
                                                    className="icon-refresh"></i></a>
                                        </div>
                                        <button title="Add To Cart" className=" add-to-cart">Add
                                            To Cart</button>
                                    </div>
                                    <div className="content">
                                        <h5 className="title"><a href="shop-left-sidebar.html">Office Furniture Table</a></h5>
                                        <span className="price">
                                            <span className="new">$38.50</span>
                                        </span>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-4  col-md-6 col-sm-6 col-xs-6" data-aos="fade-up" data-aos-delay="600">
                                {/* <!-- Single Prodect --> */}
                                <div className="product mb-5">
                                    <div className="thumb">
                                        <a href="shop-left-sidebar.html" className="image">
                                            <img src="assets/images/product-image/3.jpg" alt="Product" />
                                            <img className="hover-image" src="assets/images/product-image/4.jpg" alt="Product" />
                                        </a>
                                        <span className="badges">
                                            <span className="sale">-5%</span>
                                        </span>
                                        <div className="actions">
                                            <a href="wishlist.html" className="action wishlist" title="Wishlist"><i
                                                    className="icon-heart"></i></a>
                                            <a href="#" className="action quickview" data-link-action="quickview" title="Quick view" data-bs-toggle="modal" data-bs-target="#exampleModal"><i className="icon-size-fullscreen"></i></a>
                                            <a href="compare.html" className="action compare" title="Compare"><i
                                                    className="icon-refresh"></i></a>
                                        </div>
                                        <button title="Add To Cart" className=" add-to-cart">Add
                                            To Cart</button>
                                    </div>
                                    <div className="content">
                                        <h5 className="title"><a href="shop-left-sidebar.html">Wooden decorations</a></h5>
                                        <span className="price">
                                            <span className="new">$38.50</span>
                                        <span className="old">$40.50</span>
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* <!--  Pagination Area Start --> */}
                        <div className="pro-pagination-style text-center mb-md-30px mb-lm-30px mt-6" data-aos="fade-up">
                            <ul>
                                <li>
                                    <a className="prev" href="#"><i className="ion-ios-arrow-left"></i></a>
                                </li>
                                <li><a className="active" href="#">1</a></li>
                                <li><a href="#">2</a></li>
                                <li>
                                    <a className="next" href="#"><i className="ion-ios-arrow-right"></i></a>
                                </li>
                            </ul>
                        </div>
                        {/* <!--  Pagination Area End --> */}
                    </div>
                    {/* <!-- Shop Bottom Area End --> */}
                </div>
                {/* <!-- Sidebar Area Start --> */}
                <div className="col-lg-3 order-lg-first col-md-12 order-md-last mb-md-60px mb-lm-60px">
                    <div className="shop-sidebar-wrap">
                        {/* <!-- Sidebar single item --> */}
                        <div className="sidebar-widget">
                            <div className="main-heading">
                                <h3 className="sidebar-title">Category</h3>
                            </div>
                            <div className="sidebar-widget-category">
                                <ul>
                                    <li><a href="#" className="selected">All <span>(4)</span> </a></li>
                                    <li><a href="#" className="">Accesssories <span>(3)</span> </a></li>
                                    <li><a href="#" className="">Box <span>(5)</span> </a></li>
                                    <li><a href="#" className="">chair <span>(2)</span> </a></li>
                                    <li><a href="#" className="">Deco <span>(6)</span> </a></li>
                                    <li><a href="#" className="">Furniture <span>(4)</span> </a></li>
                                    <li><a href="#" className="">Glass <span>(1)</span> </a></li>
                                    <li><a href="#" className="">Sofa <span>(3)</span> </a></li>
                                    <li><a href="#" className="">Table <span>(4)</span> </a></li>
                                </ul>
                            </div>
                        </div>
                        {/* <!-- Sidebar single item --> */}
                        <div className="sidebar-widget-group">
                            <h3 className="sidebar-title">Filter By</h3>
                            <div className="sidebar-widget">
                                <h4 className="pro-sidebar-title">Price</h4>
                                <div className="price-filter">
                                    <div className="price-slider-amount">
                                        <input type="text" id="amount" className="p-0 h-auto lh-1" name="price" placeholder="Add Your Price" />
                                    </div>
                                    <div id="slider-range"></div>
                                </div>
                            </div>
                            {/* <!-- Sidebar single item --> */}
                            <div className="sidebar-widget">
                                <h4 className="pro-sidebar-title mt-5">Size</h4>
                                <div className="sidebar-widget-list">
                                    <ul>
                                        <li>
                                            <div className="sidebar-widget-list-left">
                                                <input type="checkbox" /> <a href="#">Large <span>(4)</span> </a>
                                                <span className="checkmark"></span>
                                            </div>
                                        </li>
                                        <li>
                                            <div className="sidebar-widget-list-left">
                                                <input type="checkbox" value="" /> <a href="#">Medium
                                                    <span>(4)</span></a>
                                                <span className="checkmark"></span>
                                            </div>
                                        </li>
                                        <li>
                                            <div className="sidebar-widget-list-left">
                                                <input type="checkbox" value="" /> <a href="#">Small <span>(4)</span>
                                                </a>
                                                <span className="checkmark"></span>
                                            </div>
                                        </li>
                                        <li>
                                            <div className="sidebar-widget-list-left">
                                                <input type="checkbox" value="" /> <a href="#">Extra
                                                    Large<span>(4)</span> </a>
                                                <span className="checkmark"></span>
                                            </div>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                            {/* <!-- Sidebar single item --> */}
                            <div className="sidebar-widget no-cba">
                                <h4 className="pro-sidebar-title">Colour</h4>
                                <div className="sidebar-widget-list">
                                    <ul>
                                        <li>
                                            <div className="sidebar-widget-list-left">
                                                <input type="checkbox" /> <a href="#">Grey<span>(2)</span> </a>
                                                <span className="checkmark grey"></span>
                                            </div>
                                        </li>
                                        <li>
                                            <div className="sidebar-widget-list-left">
                                                <input type="checkbox" value="" /> <a href="#">White<span>(4)</span></a>
                                                <span className="checkmark white"></span>
                                            </div>
                                        </li>
                                        <li>
                                            <div className="sidebar-widget-list-left">
                                                <input type="checkbox" value="" /> <a href="#">Black<span>(4)</span>
                                                </a>
                                                <span className="checkmark black"></span>
                                            </div>
                                        </li>
                                        <li>
                                            <div className="sidebar-widget-list-left">
                                                <input type="checkbox" value="" /> <a href="#">Camel<span>(4)</span>
                                                </a>
                                                <span className="checkmark camel"></span>
                                            </div>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                            {/* <!-- Sidebar single item --> */}
                            <div className="sidebar-widget">
                                <h4 className="pro-sidebar-title">Brand</h4>
                                <div className="sidebar-widget-list">
                                    <ul>
                                        <li>
                                            <div className="sidebar-widget-list-left">
                                                <input type="checkbox" /> <a href="#">Studio Design<span>(10)</span>
                                                </a>
                                                <span className="checkmark"></span>
                                            </div>
                                        </li>
                                        <li>
                                            <div className="sidebar-widget-list-left">
                                                <input type="checkbox" value="" /> <a href="#">Graphic
                                                    Corner<span>(7)</span></a>
                                                <span className="checkmark"></span>
                                            </div>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        {/* <!-- Sidebar single item --> */}
                        <div className="sidebar-widget tag">
                            <div className="main-heading">
                                <h3 className="sidebar-title mt-3">Tags</h3>
                            </div>
                            <div className="sidebar-widget-tag">
                                <ul>
                                    <li><a href="#">All</a></li>
                                    <li><a href="#">Accessories</a></li>
                                    <li><a href="#">Chair</a></li>
                                    <li><a href="#">Decoration</a></li>
                                    <li><a href="#">Furniture</a></li>
                                    <li><a href="#">Sofa</a></li>
                                    <li><a href="#">Table</a></li>
                                </ul>
                            </div>
                        </div>
                        {/* <!-- Sidebar single item --> */}
                    </div>
                </div>
            </div>
        </div>
    </div>
   )
    }
}
export default Left_Pan_Page;
