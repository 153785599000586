import React from 'react';
import {appGetUrlPath} from '../function_lib/common_cms';
import './left_menu_opt.css';
export function Left_Menu_Option(lsData,loc){
    var title="";
                var strList="";
                for(var i=0;i<lsData.length;i++){
                    if(lsData[i]["col2"]===loc){
                        title=lsData[i]["col1"];
                    }
               strList+="<li><a href=\""+lsData[i]["col2"]+"\" class=\"\">"+lsData[i]["col1"]+"</a></li>";
                
     }
     var strResp="<div class=\"col-lg-3 order-lg-first col-md-12 order-md-last mb-md-60px mb-lm-60px\">"+
     "<div class=\"sticky shop-sidebar-wrap\">"+
         "<div class=\"sidebar-widget\">"+
             "<div class=\"main-heading\">"+
                 "<h3 class=\"sidebar-title\">"+title+"</h3>"+
             "</div>"+
             "<div class=\"sidebar-widget-category\">"+
                 "<ul>";
     strResp+=strList;
     strResp+="</ul>"+
            "</div>"+
        "</div>"+
    "</div>"+
"</div>"
return strResp;
}
export default Left_Menu_Option;