import $ from 'jquery';
import React from 'react';
import {appGetUrlPath} from '../function_lib/common_cms';
import './comp_recent_blog.css';

let appPathCMS="";
let isRecent=false;
class Comp_Recent_Blog extends React.Component{
    forceUpdateHandler(){
        this.forceUpdate();
      };
    constructor(props){
        super(props);
        this.forceUpdateHandler = this.forceUpdateHandler.bind(this);
        appPathCMS=appGetUrlPath();
    }
    componentWillReceiveProps(props){
        this.props=props;
        if(this.props.blogData!==""){
           // alert(this.props.blogData.length);
            //alert("recent child=="+JSON.stringify(this.props.blogData));
            isRecent=true;
        }
        
        //alert("child props recent"+JSON.stringify(this.props.blogData));
        //alert(this.props.blogData["col4"]);
        this.forceUpdateHandler();
        //$("#blog_landing").html(this.props.blogData["col2"]);
    }
    componentDidMount(){

    }
    render(){
        return(<div className="sticky left-sidebar pt-70px shop-sidebar-wrap">
    
        <div className="sidebar-widget recent-blog-wid">
            <h3 className="sidebar-title">Recent Post</h3>
            {/* <div className="search-widget">
                <form action="#">
                    <input placeholder="Search entire store here ..." type="text" />
                    <button type="submit"><i className="icon-magnifier"></i></button>
                </form>
            </div> */}
            <br></br>
            <div className="recent-post-widget">
                {(isRecent)? this.props.blogData.map((item,index)=>{
                   var urlBlog="/blog/"+item.col1+"?rf="+item.e_id;
                   return <div className="recent-single-post d-flex">
                   <div className="thumb-side">
                       <a href={urlBlog}><img src={appPathCMS+item.col4} alt="" /></a>
                   </div>
                   <div className="media-side">
                       <a href={urlBlog}><h6>{item.col1}</h6></a>
                       
                      
                   </div>
               </div>
                }):''}
           
            </div>
        </div>
      </div>)
    }
}
export default Comp_Recent_Blog;