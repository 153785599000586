import $ from 'jquery';
import React from 'react';
import {appGetUrlPath,RespBkMthdMapp,AjaxCallMethod,CallMapMethod_CBack,
    ajaxindicatorstart,ajaxindicatorstop} from '../function_lib/common_lib';
//import Regular_Explore from '../component_version2/regular_explore';

let proData;
let appUrtPath;
let description;
let composition;
let imgPath;

let checkAvgStar=0;
let rateData=[];
let strAvgStar="";
let logInObj=[];
class Single_Product extends React.Component{
   
    callBackRating(data,msg){
        //alert(data);
        if(data!=="0"){
            alert("Thanks for Review");
            $("#frmSingCommentForm").find("input[type=text]").val("");
        }
    }  
    constructor(props){
      super(props);
      appUrtPath=appGetUrlPath();
      if(localStorage.getItem("userDS")!=null){

        logInObj=JSON.parse(localStorage.getItem("userDS"));
}
    
      if(typeof this.props.proData!=="undefined"){
        //alert(JSON.stringify(proData));
        proData=this.props.proData;
        rateData=this.props.ratings;
        /* Apply Avg Rating */
        let sumRate=0;
        for(var r=0;r<rateData.length;r++){
            sumRate+=parseInt(rateData[r].rating);
        }
        checkAvgStar=sumRate/rateData.length;
       
        for(var a=0;a<5;a++){
            if(checkAvgStar>0 && checkAvgStar<1){
                strAvgStar+="<i class=\"ion-android-star-half\"></i>";
            }else if(checkAvgStar>=1){
                strAvgStar+="<i class=\"ion-android-star\"></i>";
            }else{
                strAvgStar+="<i class=\"ion-android-star-outline\"></i>";
            }
           
           checkAvgStar--;
        }
    


        imgPath=["default-product.png"];
        //alert(proData[0]["img_path"]);  
        if(proData[0]["img_path"]!==""){
            //alert("i ma in condi");
         imgPath=proData[0]["img_path"].split(',');
          }
        //composition= $.parseHTML();  
       //alert(JSON.stringify(this.props.proData));
      }
    }

    checkDeliverStatus(){
        //alert("I am testing..");
        let mapObj=[];
        mapObj.push({name:"curl_type",value:"checkServiceAvail"});
        mapObj.push({name:"res_func",value:"callBackInvoicePg"});
        mapObj.push({name:"check_pincode",value:$("#appo_patient_name").val()});
        //mapObj.push({name:"party_info_id",value:partyInfoId});
        mapObj["res_func"]="addToWishList";
        mapObj["curl_type"]="checkServiceAvail";
        ajaxindicatorstart("Please wait");
        CallMapMethod_CBack(this,mapObj,function(data,msg){
            ajaxindicatorstop();
            $("#availRespPin").html(msg);
            //alert(msg);
        });
        
    }

    addToWishList(itemId,partyInfoId){
        let mapObj=[];
        mapObj.push({name:"curl_type",value:"addToWishList"});
        mapObj.push({name:"res_func",value:"callBackInvoicePg"});
        mapObj.push({name:"item_id",value:itemId});
        mapObj.push({name:"party_info_id",value:partyInfoId});
        mapObj["res_func"]="addToWishList";
        mapObj["curl_type"]="addToWishList";
        CallMapMethod_CBack(this,mapObj,function(data,msg){
            //alert(data);
            alert("Added to wishlist");
        });
     
        
    }

    goToReview(tab){
        //alert("i m in click review");
        //$("#clickReview").click();
        let checkActiv=document.getElementsByClassName("description-review-topbar");
        for(let i=0;i<checkActiv[0].childNodes.length;i++){
            checkActiv[0].childNodes[i].classList.remove("active");
        }
        let divActiv=document.getElementsByClassName("description-review-bottom");
        for(let i=0;i<divActiv[0].childNodes.length;i++){
            divActiv[0].childNodes[i].classList.remove("active");
        }

        $("#clickReview").addClass("active");
        $(tab).addClass("active");
    }

    executeRating(stars) {
        
        const starClassActive = "rating__star ion-android-star";
        const starClassInactive = "rating__star ion-android-star-outline";
        const starsLength = stars.length;
        let i;
        stars.map((star,i) => {
          star.onclick = () => {
             // alert("i am in click");
            i = stars.indexOf(star);
            //alert(i);
            $("#rating").val((i+1));
            if (star.className===starClassInactive) {
              for (i; i >= 0; --i) stars[i].className = starClassActive;
            } else {
              for (i; i < starsLength; ++i) stars[i].className = starClassInactive;
            }
          };
        });
      }
    componentDidMount(){
       //$("#composition").html(proData[0]["composition"]);
     //alert(proData[0]["status"]);
       
       $("#pro_description").html(proData[0]["product_description"]);
       $("#pro_usage").html(proData[0]["usage"]);
       $("#pro_composition").html(proData[0]["composition"]);
       $("#pro_faq").html(proData[0]["faq"]);
       $(".product_desc").html(proData[0]["product_description"]);


       const ratingStars = [...document.getElementsByClassName("rating__star")];
       //    alert(ratingStars.length);
          this.executeRating(ratingStars);
          $("#landing_rating").html(strAvgStar);
       
       //alert("i am in click set");
       const script = document.createElement("script");
       script.src = "/main.js";
       script.async = true;
       //script.onload = () => clsObject.scriptLoaded();
     
       document.body.appendChild(script);
       
       AjaxCallMethod(this,"okSuccCNOT");
    }
    
    render(){
        return(<div><div className="product-details-area pt-50px pb-50px">
        <div className="container">
        <form>
            <div className="row">
           
    <input type="hidden" name="item_name" value={proData[0]["product_name"]} />
    <input type="hidden" name="item_code" value={proData[0]["product_code"]}/>
    <input type="hidden" name="item_rate" value={proData[0]["cost"]}/>
    <input type="hidden" name="pre_cost" value={proData[0]["pre_cost"]}/>
    <input type="hidden" name="offer_per" value={proData[0]["offer_per"]}/>
    <input type="hidden" name="comp_name" value={"0"}/>
    <input type="hidden" name="imgs" value={imgPath[0]} />
    <input type="hidden" name="item_rf_id" value={proData[0]["id"]} />

                <div className="col-lg-5 col-sm-12 col-xs-12 mb-lm-30px mb-md-30px mb-sm-30px">
                    {/* <!-- Swiper --> */}
                    <div className="swiper-container zoom-top">
                        <div className="swiper-wrapper">
                            {imgPath.map(imgNm=>(
   <div className="swiper-slide zoom-image-hover">
   <img className="img-responsive m-auto" src={appUrtPath+imgNm} alt="" />
</div>
                            ))

                            }
                        </div>
                    </div>
                    <div className="swiper-container zoom-thumbs slider-nav-style-1 small-nav mt-3 mb-3">
                        <div className="swiper-wrapper">
                            {imgPath.map(imgNm=>(
 <div className="swiper-slide">
 <img className="img-responsive m-auto" src={appUrtPath+imgNm} alt=""/>
</div>
                            ))}
                           
                           
                        </div>
                      
                        <div className="swiper-buttons">
                            <div className="swiper-button-next"></div>
                            <div className="swiper-button-prev"></div>
                        </div>
                    </div>
                </div>
                <div className="col-lg-7 col-sm-12 col-xs-12" data-aos="fade-up" data-aos-delay="200">
                    <div className="product-details-content quickview-content">
                        <h2>{proData[0]["product_name"]}</h2>
                        {parseFloat(proData[0]["max_limit_qty"])<=0?
                        <span class="badges">
                      <span class="out">{"Out of Stock"}</span>
                     </span>:''}
                     {proData[0]["status"]!=="allow"?<span class="badges">
                        <span class="out">{"Out of Stock"}</span>
                     </span>:''}
                            {/* <p className="reference">Reference:<span> {proData[0]["product_code"]}</span></p> */}
                          

                            <div className="pro-details-rating-wrap">
                            <div id="landing_rating" className="rating-product">
                                <i className="ion-android-star"></i>
                                <i className="ion-android-star"></i>
                                <i className="ion-android-star"></i>
                                <i className="ion-android-star"></i>
                                <i className="ion-android-star"></i>
                            </div>
                            <span className="read-review nav mt-3"><a className="reviews" onClick={()=>this.goToReview("#des-details4")} href="#main-tab-section">Read reviews ({rateData.length})</a></span>
                           
                        </div>
                        <div className="pro-details-wish-com">
                            <div className="pro-details-wishlist mt-2">
                                {logInObj.length>0?<a className="chkWishList" onClick={()=>this.addToWishList(proData[0]["id"],logInObj[0]["id"])} href="javascript:void(0)"><i className="ion-android-favorite-outline"></i>Add to
                                    wishlist</a>:<a href="../login"><i className="ion-android-favorite-outline"></i>Add to
                                    wishlist</a>}
                                
                            </div>
                             {/* <div className="pro-details-compare">
                                <a href="compare.html"><i className="ion-ios-shuffle-strong"></i>Add to compare</a>
                            </div> */}
                        </div>
                        <div className="pricing-meta">
                            <ul>
                                {proData[0]["cost"]!==proData[0]["pre_cost"]?
                                <li className="old-price old">₹ {proData[0]["pre_cost"]}</li>:''
                                }
                                
                                <li className="old-price not-cut">₹ {proData[0]["cost"]}</li>
                            </ul>
                        </div>
                        <div className="pro-details-quality">
                            <div className="cart-plus-minus">
                                <input className="cart-plus-minus-box" type="text" name="item_qty" value="1" />
                            </div>
                            <div className="pro-details-cart">
                               {parseFloat(proData[0]["max_limit_qty"])<=0 || proData[0]["status"]!=="allow"? 
                               <button type="button" className="add-cart btn _addToCart12121 btn-primary btn-hover-primary ml-4" href="javascript:void(0)">Not in Stock</button>
                                :<button type="button" className="add-cart btn _addToCart btn-primary btn-hover-primary ml-4" href="javascript:void(0)"> Add To
                                Cart</button>
                                    
                                    }
                            </div>
                        </div>
                        
                        
                        <div className="pro-details-social-info">
                            <span>Share</span>
                            <div className="social-info">
                                <ul className="d-flex">
                                    <li>
                                        <a target="_blank" href="https://www.facebook.com/sharer/sharer.php?u=test.fastrcdirect.com"><i className="ion-social-facebook"></i></a>
                                    </li>
                                    <li>
                                        <a target="_blank" href="https://twitter.com/intent/tweet?text=test.fastrcdirect.com"><i className="ion-social-twitter"></i></a>
                                    </li>
                                    <li>
                                        <a target="_blank" href="http://www.tumblr.com/share/link?url=https://test.fastrcdirect.com"><i className="icon-social-tumblr"></i></a>
                                    </li>
                                    <li>
                                        <a target="_blank" href="https://www.instagram.com/?url=https://test.fastrcdirect.com"><i className="ion-social-instagram"></i></a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                            <div className="pro-details-social-info11">
                            
                           
                                <form id="frmPincode" name="frmPincode">
                                    <div className="row">
                                        <div className="col-lg-6 col-md-12">
                                <div className="form-group">
                                <label>Check delivery via pincode</label>
                                <input className="form-control form-control-sm required" autocomplete="off" type="text" id="appo_patient_name" name="appo_patient_name" />
                                 </div>
                                 </div>
                                 {/* <div class="col-lg-12 col-md-12 float-left" >
                                 
                                 </div> */}
                                 <label id="availRespPin"></label>
                                 <div className="col-lg-12 col-md-12">
                                 <div className="form-group mt-4 float-left">
                                 <button type="button" onClick={()=>this.checkDeliverStatus()} className="btn text-center btn-secondary btn-hover-primary ml-4 float-right">Check Delivery</button>
                                 </div>
                                 </div>
                                 
                                 </div>
                                
                                </form>
                                
                            
                            </div>

                        <div className="description-review-area" id="pro_description">
                    
                    </div>
                       
                    </div>
                </div>
                <div className="col-lg-4 col-sm-12 col-xs-12" data-aos="fade-up" data-aos-delay="200">
                    {/* <div className="description-review-area description-review-bottom" id="pro_usage">
                    
                    </div> */}
                </div>
            </div>
            </form>
        </div>
    </div>
    <div className="description-review-area pb-100px">
    <div id="main-tab-section" className="container">
        <div className="description-review-wrapper">
            <div className="description-review-topbar nav">
                <a className="active" data-bs-toggle="tab" href="#des-details1">Product Details</a>
                {/* <a data-bs-toggle="tab" href="#des-details2">Features</a>
                <a data-bs-toggle="tab" href="#des-details3">Specifications</a>
                
                <a id="clickReview" data-bs-toggle="tab" href="#des-details4">Reviews ({rateData.length})</a> */}
            </div>
            <div className="tab-content description-review-bottom">
                <div id="des-details1" className="tab-pane active">
                    <div className="product-description-wrapper">
                        
                        <p id="pro_usage">
                        </p>
                        
                    </div>
                </div>
                {/* <div id="des-details2" className="tab-pane">
                    <div className="product-anotherinfo-wrapper" id="pro_composition">
                       
                    </div>
                </div> */}
                {/* <div id="des-details3" className="tab-pane">
                    <div className="product-anotherinfo-wrapper" id="pro_faq">
                      
                    </div>
                </div> */}
                {/* <div id="des-details4" className="tab-pane">
                    <div className="row">
                        <div className="col-lg-7">
                            <div className="review-wrapper">
                            {(rateData.length!==0?rateData.map((item,index)=>{
                                let countStar=parseInt(item.rating);
                                
                                var strStar=[];
                                var notStar=[];
                                for(var s=1;s<=countStar;s++){
                                    strStar.push([s]);
                                   // strStar+="<i className=\"ion-android-star\"></i>";
                                }
                                for(var s=1;s<=5-countStar;s++){
                                  notStar.push([s]);
                                    // strStar+="<i className=\"ion-android-star-outline\"></i>";
                                }

                                return <div className="single-review">
                                    <div className="review-img">
                                    <img src="../user.png" alt="" />
                                    </div>
                                    <div className="review-content">
                                        <div className="review-top-wrap">
                                            <div className="review-left">
                                                <div className="review-name">
                                                <h4>{item.customer_name}</h4>
                                                </div>
                                                <div className="rating-product">
                                                {(strStar.map((star)=>{
                                                   return <i className="ion-android-star"></i>
                                               }))}
                                                {(notStar.map((star)=>{
                                                   return <i className="ion-android-star-outline"></i>
                                               }))}
                                                </div>
                                            </div>
                                            <div className="review-left">
                                               
                                            </div>
                                        </div>
                                        <div className="review-bottom">
                                            <p>
                                            {item.comment}
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            }):'')}
                               
                            </div>
                        </div>
                        <div className="col-lg-5">
                            <div className="ratting-form-wrapper pl-50">
                                <h3>Add a Review</h3>
                                <div className="ratting-form">
                                    <form id="frmSingCommentForm">
                                    <input type="hidden" name="curl_type" id="curl_type" value="addProductRating" />  
                <input type="hidden" name="m_type" id="m_type" value="addProductRating" />
                <input type="hidden" name="res_func" id="res_func" value="callBackRating" />
                <input type="hidden" name="account_info_id" id="account_info_id" value="0"/>
                <input type="hidden" name="product_master_id" id="product_master_id" value={proData[0]["id"]}/>
                <input type="hidden" name="invoice_id" id="invoice_id" value="0" />
                <input type="hidden" name="gb_token" id="gb_token" value="5e7hmtL7R0GU7zEBVhJZmrKx8Qb4xy-G_ihGPxqUtrGt5qMHlOaQK8ba1r-o7qKuAChRCIL0_Pilz1gStWW2Mg" />
                <input type="hidden" name="rating" id="rating" value="5"/>
                <input type="hidden" name="pg_id" id="pg_id" value="0"/>
                <input type="hidden" name="component_id" id="component_id" value="0"/>
                                        <div className="star-box">
                                            <span>Your rating:</span>
                                            <div className="rating-product">
                                                <i className="rating__star ion-android-star"></i>
                                                <i className="rating__star ion-android-star"></i>
                                                <i className="rating__star ion-android-star"></i>
                                                <i className="rating__star ion-android-star"></i>
                                                <i className="rating__star ion-android-star"></i>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-md-6">
                                                <div className="rating-form-style">
                                                    <input placeholder="Name" type="text" id="customer_name" name="customer_name" />
                                                </div>
                                            </div>
                                            <div className="col-md-6">
                                                <div className="rating-form-style">
                                                    <input placeholder="Email" type="email" id="contact_no" name="contact_no" />
                                                </div>
                                            </div>
                                            <div className="col-md-12">
                                                <div className="rating-form-style form-submit">
                                                    <textarea name="Your Review" placeholder="Message" name="comment" id="comment"></textarea>
                                                    <button className="btn btn-primary btn-hover-color-primary _btnSetColor"  type="button" value="Submit">Submit</button>
                                                </div>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div> 
                </div>*/}
            </div>
        </div>
    </div>
    {/* <Regular_Explore/> */}
</div>
</div>
    )
    }
}
export default Single_Product;