import React,{useState} from 'react';
import {TableDatePicker,btnSetColor_CBack} from '../function_lib/common_lib';
import TimePicker from 'react-time-picker';

import ImgAppoint from '../assets/images/appoint_thumb.png';
import 'react-time-picker/dist/TimePicker.css';
import 'react-clock/dist/Clock.css';
export const addConsultation=(frmName)=>{
    btnSetColor_CBack(this,frmName,"addConsultation",function(data,msg){
        if(data===-10 || data==="-10"){
            alert("Kinldy fill required details..");
        }else if(data==="001"){
            alert("Appointment request submitted successfully, you will receive a schedule call at the earliet , Thanks");
            window.location.reload();
        }else{
            alert("Unable submit request ,kindly check and try again");
        }
    })
}
export const Comp_Book_Appointment_Form=(props)=>{
    const [selectedTime, setSelectedTime] = useState('12:00');

    const handleTimeChange = (newTime) => {
      setSelectedTime(newTime);
    };
  
      let orderNos=[];
      //alert(JSON.stringify(props.trackOrder));
      if(typeof props.trackOrder!=="undefined"){
          //orderNos=props.trackOrder.order_no.split(',');
          if(props.trackOrder.track_url!==""){
              orderNos=JSON.parse(props.trackOrder.track_url);
          }
          
          //alert("test=="+orderNos);
      }
      return(<div className="modal111 fade11 bg-area1" id="divBookAppointmentForm" tabindex="-1" role="dialog">
      
      <div className="modal-dialog" role="document">
          <div className="modal-content">
              {/* <div className="modal-header">
                  
                  <button type="button" className="close" data-bs-dismiss="modal" data-dismiss="modal" aria-label="Close"><span
                          aria-hidden="true">x</span></button>
              </div> */}
              <div className="modal-body">
                  <div className="row">
                      <div className="col-md-5 bg-color3 col-sm-12 col-xs-12 mb-lm-30px mb-sm-30px pt-70px pb-70px">
                     <h1 class="white center pl-5 pr-5"> Fill out
  this form
  for booking
  online.
  </h1>
                          <img src={ImgAppoint} style={{"max-width":"90%"}}  />
                        
                      </div>
                      <div className="col-md-7 col-sm-12 col-xs-12">
                          <div className="product-details-content quickview-content">
                              <h2></h2>
                      <form id="frmBookAppointmentForm" name="frmBookAppointmentForm" className="form-control11">
                          <div>
                          <input type="hidden" name="curl_type" id="curl_type" value="addConsultation" />  
                  <input type="hidden" name="m_type" id="m_type" value="addConsultation" />
                  <input type="hidden" name="res_func" id="res_func" value="okSuccConsultation" />
                          </div>
                          <div className="row">
  
                           <div className="col-lg-6 col-md-12 mb-2 pl-2 pr-2">
                              <div className="form-group">
                                  <label>Person Name</label>
                                  <input className="form-control form-control-sm required" autocomplete="off" type="text" id="appo_patient_name" name="appo_patient_name" />
                              </div>
                           </div>
                           <div className="col-lg-6 col-md-12 mb-2 pl-2 pr-2">
                              <div className="form-group">
                                  <label>Gender</label>
                                  <select id="appo_gender" name="appo_gender" className="form-control form-control-sm required">
                                      <option value={""}>-select-</option>
                                      <option value={"Male"}>Male</option>
                                      <option value={"Female"}>Female</option>
  
                                  </select>
                              </div>
                           </div>
  
                           <div className="col-lg-6 col-md-12 mb-2 pl-2 pr-2">
                              <div className="form-group">
                                  <label>Contact No</label>
                                  <input className="form-control required" autocomplete="off" type="text" id="appo_contact_no" name="appo_contact_no" />
                              </div>
                           </div>
                           <div className="col-lg-6 col-md-12 mb-2 pl-2 pr-2">
                              <div className="form-group">
                                  <label>Email</label>
                                  <input className="form-control required" autocomplete="off" type="text" id="appo_email_id" name="appo_email_id" />
                              </div>
                           </div>
                           <div className="col-lg-6 col-md-12 mb-2 pl-2 pr-2">
                              <div className="form-group">
                                  <label>Appointment Date</label>
                                  <TableDatePicker needTime={true} refName={"appo_req_date"} />
                              </div>
                           </div>
                          
                           <div className="col-lg-6 col-md-6 mb-2 pl-2 pr-2">
                              <div className="form-group">
                                  <label>Therapy</label>
                                  <select className="form-control required" id="appo_structure" name="appo_structure">
                                      <option value={""}>-select-</option>
                                      <option value={"Ayurvedic Therapy"}>Ayurvedic Therapy</option>
                                      <option value={"Aromatherapy"}>Aromatherapy</option>
                                      <option value={"Diet & Nutrition"}>Diet & Nutrition</option>
                                      <option value={"Naturopathy"}>Naturopathy</option>
                                      <option value={"Physiotherapy"}>Physiotherapy</option>
                                      <option value={"Reiki"}>Reiki</option>
                                      <option value={"Yoga & Meditation"}>Yoga & Meditation</option>
  
                                  </select>
                              </div>
                           </div>
                           <div className="col-lg-12 col-md-12 mb-2 pl-2 pr-2">
                              <div className="form-group">
                                  <label>Note/Description</label>
                                  <textarea className="form-control required" id="appo_message" name="appo_message"></textarea>
                              </div>
                           </div>
  
                           <div className="col-lg-12 col-md-12 mb-2 pl-2 pr-2">
                              <div className="form-group">
                                  <button type="button" onClick={()=>addConsultation("frmBookAppointmentForm")} className="btn text-center btn-primary btn-hover-primary ml-4 float-right">Book Now</button>
                              </div>
                           </div>
                              
  
                          </div>
                           </form>
                              
                              
                       </div>
                      </div>
                  </div>
              </div>
          </div>
      </div>
      </div>)
}
export const Comp_Book_Appointment=(props)=>{
    const [selectedTime, setSelectedTime] = useState('12:00');

  const handleTimeChange = (newTime) => {
    setSelectedTime(newTime);
  };

    let orderNos=[];
    //alert(JSON.stringify(props.trackOrder));
    if(typeof props.trackOrder!=="undefined"){
        //orderNos=props.trackOrder.order_no.split(',');
        if(props.trackOrder.track_url!==""){
            orderNos=JSON.parse(props.trackOrder.track_url);
        }
        
        //alert("test=="+orderNos);
    }
    return(<div className="modal fade" id="divBookAppointment" tabindex="-1" role="dialog">
    
    <div className="modal-dialog" role="document">
        <div className="modal-content">
            <div className="modal-header">
                
                <button type="button" className="close" data-bs-dismiss="modal" data-dismiss="modal" aria-label="Close"><span
                        aria-hidden="true">x</span></button>
            </div>
            <div className="modal-body">
                <div className="row">
                    <div className="col-md-5 bg-color3 col-sm-12 col-xs-12 mb-lm-30px mb-sm-30px pt-70px pb-70px">
                   <h1 class="white center pl-5 pr-5"> Fill out
this form
for booking
online.
</h1>
                        <img src={ImgAppoint} style={{"max-width":"90%"}}  />
                      
                    </div>
                    <div className="col-md-7 col-sm-12 col-xs-12">
                        <div className="product-details-content quickview-content">
                            <h2></h2>
                    <form id="frmBookAppointment" name="frmBookAppointment" className="form-control11">
                        <div>
                        <input type="hidden" name="curl_type" id="curl_type" value="addConsultation" />  
                <input type="hidden" name="m_type" id="m_type" value="addConsultation" />
                <input type="hidden" name="res_func" id="res_func" value="okSuccConsultation" />
                        </div>
                        <div className="row">

                         <div className="col-lg-6 col-md-12 mb-2 pl-2 pr-2">
                            <div className="form-group">
                                <label>Person Name</label>
                                <input className="form-control form-control-sm required" autocomplete="off" type="text" id="appo_patient_name" name="appo_patient_name" />
                            </div>
                         </div>
                         <div className="col-lg-6 col-md-12 mb-2 pl-2 pr-2">
                            <div className="form-group">
                                <label>Gender</label>
                                <select id="appo_gender" name="appo_gender" className="form-control form-control-sm required">
                                    <option value={""}>-select-</option>
                                    <option value={"Male"}>Male</option>
                                    <option value={"Female"}>Female</option>

                                </select>
                            </div>
                         </div>

                         <div className="col-lg-6 col-md-12 mb-2 pl-2 pr-2">
                            <div className="form-group">
                                <label>Contact No</label>
                                <input className="form-control required" autocomplete="off" type="text" id="appo_contact_no" name="appo_contact_no" />
                            </div>
                         </div>
                         <div className="col-lg-6 col-md-12 mb-2 pl-2 pr-2">
                            <div className="form-group">
                                <label>Email</label>
                                <input className="form-control required" autocomplete="off" type="text" id="appo_email_id" name="appo_email_id" />
                            </div>
                         </div>
                         <div className="col-lg-6 col-md-12 mb-2 pl-2 pr-2">
                            <div className="form-group">
                                <label>Appointment Date</label>
                                <TableDatePicker needTime={true} refName={"appo_req_date"} />
                            </div>
                         </div>
                        
                         <div className="col-lg-6 col-md-6 mb-2 pl-2 pr-2">
                            <div className="form-group">
                                <label>Therapy</label>
                                <select className="form-control required" id="appo_structure" name="appo_structure">
                                    <option value={""}>-select-</option>
                                    <option value={"Ayurvedic Therapy"}>Ayurvedic Therapy</option>
                                    <option value={"Aromatherapy"}>Aromatherapy</option>
                                    <option value={"Diet & Nutrition"}>Diet & Nutrition</option>
                                    <option value={"Naturopathy"}>Naturopathy</option>
                                    <option value={"Physiotherapy"}>Physiotherapy</option>
                                    <option value={"Reiki"}>Reiki</option>
                                    <option value={"Yoga & Meditation"}>Yoga & Meditation</option>

                                </select>
                            </div>
                         </div>
                         <div className="col-lg-12 col-md-12 mb-2 pl-2 pr-2">
                            <div className="form-group">
                                <label>Note/Description</label>
                                <textarea className="form-control required" id="appo_message" name="appo_message"></textarea>
                            </div>
                         </div>

                         <div className="col-lg-12 col-md-12 mb-2 pl-2 pr-2">
                            <div className="form-group">
                                <button type="button" onClick={()=>addConsultation("frmBookAppointment")} className="btn text-center btn-primary btn-hover-primary ml-4 float-right">Book Now</button>
                            </div>
                         </div>
                            

                        </div>
                         </form>
                            
                            
                     </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    </div>)
}
export default Comp_Book_Appointment;