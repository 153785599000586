import $ from 'jquery';
import React from 'react';
import {appGetUrlPath} from '../function_lib/common_cms';
import './time_line.css';
export function Time_Line(lsData){
    //alert(lsData);
    var appPath= appGetUrlPath();
    var strResp="<div class=\"timeline\">";
    for(var i=0;i<lsData.length;i++){
        if(i%2==0){
           strResp+="<div class=\"timeline__event  animated fadeInUp delay-3s timeline__event--type1\">"+
                    "<div class=\"timeline__event__icon\">"+
                    "<i class=\"lni-cake\"></i>"+
                    "</div>"+
           "<div class=\"timeline__event__date\">"+
           "<img src=\""+appPath+lsData[i]["col4"]+"\" alt=\"\" class=\" img-responsive w-100\" />"+
           "</div>"+
           "<div class=\"timeline__event__content\">"+
            "<div class=\"timeline__event__title\">"+
            lsData[i]["col1"]+
             "</div>"+
             "<div class=\"timeline__event__description\">"+
               "<p>"+lsData[i]["col2"]+"</p>"+
             "</div>"+
           "</div>"+
         "</div>";
        }else{
            strResp+="<div class=\"timeline__event  animated fadeInUp delay-3s timeline__event--type1\">"+
            "<div class=\"timeline__event__icon\">"+
            "<i class=\"lni-cake\"></i>"+
            "</div>"+
   "<div class=\"timeline__event__date\">"+
   "<img src=\""+appPath+lsData[i]["col4"]+"\" alt=\"\" class=\" img-responsive w-100\" />"+
   "</div>"+
   "<div class=\"timeline__event__content\">"+
    "<div class=\"timeline__event__title\">"+
    lsData[i]["col1"]+
     "</div>"+
     "<div class=\"timeline__event__description\">"+
       "<p>"+lsData[i]["col2"]+"</p>"+
     "</div>"+
   "</div>"+
 "</div>";
        }
    }
    return strResp+"</div>";
}

export default Time_Line;