import $ from 'jquery';
import React from 'react';
import { useLocation } from 'react-router-dom';
import {CallMapMethod_CBack} from '../function_lib/common_lib';
import QueryString from 'query-string';

export function setParamsTransInvoice(locUrl){
    //alert(JSON.stringify(locUrl));
    let tokenId=locUrl.pathname.split('/')[2];
    let params= QueryString.parse(locUrl.search);
    

    let mapStrip=[];
    mapStrip.push({name:"curl_type",value:"updateStripePayStatus"});
    mapStrip.push({name:"res_func",value:"callBackSt"});
    mapStrip.push({name:"token",value:tokenId});
    mapStrip.push({name:"redirect_status",value:params.redirect_status});
    mapStrip.push({name:"payment_intent",value:params.payment_intent});
    mapStrip.push({name:"payment_intent_client_secret",value:params.payment_intent_client_secret})

        mapStrip["res_func"]="callBackSt";
        mapStrip["curl_type"]="updateStripePayStatus";
        
        CallMapMethod_CBack(this,mapStrip,function(data,msg){
            //alert(data);
            if(data!=="0" && data!==""){
                //let jsData=JSON.parse(data);
                //const clientSecret = jsData.client_secret;
                window.location.href="../invoice_receipt?rf="+params.payment_intent_client_secret+"&rfkey="+tokenId;   
      
            }
        })
        
}
export const Secure_Redirect=()=>{
    let locUrl=useLocation();
    
    setParamsTransInvoice(locUrl);
return (<div className="checkout-area pt-100px pb-100px">
<div className="container">
Don't click back or close browser while we are processing your payment
    </div>
    </div>
    )

}
export default Secure_Redirect;