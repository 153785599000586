import React from 'react';
import {appGetUrlPath} from '../function_lib/common_cms';
import spec1 from '../assets/comp_image/speciality1.jpg';
import './image_text_vtwo.css';
const Image_Text_VTwo=(props)=>{
    let appUrtPath=appGetUrlPath();
    let lsData=props.lsData;
    return (
        <section class="u-section u-grey u-clearfix">
		<div class="u-shape u-palette-base u-expanded-height"></div>
		<div class="u-palette-2-base u-radius-25 u-shape u-shape-round u-shape-2"></div>
		{/* <!-- image --> */}
		<img src={spec1} class="u-align-left u-image u-image-round u-radius-25 u-image-1" data-image-width="1380" data-image-height="920" />

		<div class="u-align-left u-container-style u-group u-radius-50 u-shape-round u-similar-fill u-white u-group-1">
			<div class="u-container-layout u-padding-12 u-valign-middle u-container-layout-1">
				<h3 class="u-text u-text-default u-text-1 black">Custom parts exprience</h3>
				<p class="u-text u-text-2">Discover a world where precision meets personalization. Our specialty collection caters to enthusiasts seeking the extraordinary—whether it's crafting a bespoke racing experience through custom builds or finding the exact replacement parts to keep your machine in top form. Dive into our range of racing products designed for champions seeking that competitive edge. From custom builds that reflect your unique style to meticulously curated replacement parts ensuring peak performance, we're here to fuel your passion for speed and precision. Explore our specialty lineup and elevate your racing game to a whole new level. </p>
				{/* <p class="u-text u-text-3">Image from 
					<a href="" class="u-active-none u-border-1 u-border-active-grey-60 u-border-black u-border-hover-grey-60 u-btn u-button-link u-button-style u-hover-none u-none u-text-body-color u-btn-1">Freepik</a>
          		</p> */}
          		{/* <a href="" class="u-active-palette-1-base u-border-none u-btn u-btn-round u-button-style u-hover-palette-1-base u-palette-2-base u-radius-50 u-text-active-palette-1-base u-text-hover-palette-1-base u-btn-2">read more</a> */}
			</div>
		</div>
	</section>
)
}
export default Image_Text_VTwo;