import React from 'react';
import pro1 from '../assets/images/icons/1.png';
import pro2 from '../assets/images/icons/2.png';
import pro3 from '../assets/images/icons/3.png';
import pro4 from '../assets/images/icons/4.png';
import pro5 from '../assets/images/icons/5.png';
import pro6 from '../assets/images/icons/6.png';


import './features_slider.css';

export function Features_Slider(){
    var strResp="";
    strResp+="<div data-aos=\"zoom-in-left\" class=\"feature_slider_bg_none orange-bg border-box section pt-70px pb-100px\">"+
    "<div class=\"container\">"+
        "<div class=\"category-slider swiper-container\">"+
            "<div class=\"category-wrapper swiper-wrapper\">"+
                
            "<div class=\" swiper-slide\">"+
            "<a href=\"../health_care\" class=\"category-inner \">"+
                "<div class=\"category-single-item feat_img\">"+
                    "<img src=\""+pro1+"\" alt=\"\" />"+
                    "<span class=\"title\">Racing Cars</span>"+
                "</div>"+
            "</a>"+
        "</div>"+
        "<div class=\" swiper-slide\">"+
            "<a href=\"../products\" class=\"category-inner feat_img\">"+
                "<div class=\"category-single-item\">"+
                    "<img src=\""+pro2+"\"} alt=\"\" />"+
                    "<span class=\"title\">Drons</span>"+
                "</div>"+
            "</a>"+
        "</div>"+
        
        "<div class=\" swiper-slide\">"+
            "<a href=\"../Panchkarma_yoga\" class=\"category-inner feat_img\">"+
                "<div class=\"category-single-item\">"+
                    "<img src=\""+pro3+"\" alt=\"\" />"+
                    "<span class=\"title\">Ships and Boat</span>"+
                "</div>"+
            "</a>"+
        "</div>"+
        
        "<div class=\" swiper-slide\">"+
            "<a href=\"../Diet_Lifestyle\" class=\"category-inner feat_img\">"+
                "<div class=\"category-single-item\">"+
                    "<img src=\""+pro4+"\"} alt=\"\" />"+
                    "<span class=\"title\">Airplanes</span>"+
                "</div>"+
            "</a>"+
        "</div>"+
        
        "<div class=\"swiper-slide\">"+
            "<a href=\"../pregnancy_care\" class=\"category-inner feat_img\">"+
                "<div class=\"category-single-item\">"+
                    "<img src=\""+pro5+"\" alt=\"\" />"+
                    "<span class=\"title\">Halicopters</span>"+
                "</div>"+
            "</a>"+
        "</div>"+
       
    "</div>"+
"</div>"+
"</div>"+
"</div>";
//alert("testin g=="+strResp);
return strResp;
}
export default Features_Slider;