import React from 'react';
import {appGetUrlPath} from '../function_lib/common_cms';
import './feature_web_list.css';
export function Feature_Web_List(lsData){
   // alert(lsData);
    var appUrtPath=appGetUrlPath();
var listData=lsData;
var strResp="";
var strHeader="<div class=\"section feature_web_bg mb-50px pt-70px pb-100px\">"+
              "<div class=\"container\">"+
              "<div class=\"row\">";

var strFooter="</div></div></div>";
for(var i=0;i<listData.length;i++){
    strResp+="<div class=\"col-lg-4 col-12 mb-md-30px mb-lm-30px\">"+
             "<div class=\"feature_web banner-2\">"+
             "<img src=\""+appUrtPath+lsData[i]["col4"]+"\" alt=\"\" />"+
             "<div class=\"info justify-content-start\">"+
            "<div class=\"content align-self-center\">"+
                "<h3 class=\"title\">"+
                lsData[i]["col1"]+
                "</h3>"+
                "<a href=\"javascript:void(0)\" class=\"shop-link\">Shop Now</a>"+
            "</div>"+
        "</div>"+
    "</div>"+
"</div>";
}
//alert(strHeader+strResp+strFooter);
return strHeader+strResp+strFooter;
}
export default Feature_Web_List;