import React from 'react';
import Front_Header from '../pages/front_header';
import Img_Text_Section from './img_text_section';
import Left_Pan_Page from './left_pan_page';
class All_Component extends React.Component{
    render(){
        return(<div>
        <Front_Header/>
        <Img_Text_Section/>
        <Left_Pan_Page/>
        </div>)
    }
}
export default All_Component;