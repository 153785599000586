import React from 'react';
import {appGetUrlPath} from '../function_lib/common_lib';
 import './image_slider.css';
export function Image_Slider(lsData,clsName="new-product-slider"){
   // alert(JSON.stringify(lsData));
/*Header Values */
clsName="blog-slider";
var title="";
if(lsData.length!=0){
title=lsData[0]["g_title"];
}
//var appPath=appGetUrlPath();
var appPath="../";
//var listData=JSON.parse(lsData);
var strResp="";

var strHeader="<div  class=\"main-blog-area bg-area1 pt-10px\">"+
"<div class=\"container\">"+
    "<div class=\"row\">"+
        "<div style=\"text-align:center\" class=\"col-md-12\">"+
            "<div style=\"display:inline-block;\" class=\"flag bottom-boder heading section-heading  page__title-bar\">"+
                // "<h5 class=\"title text-white ml-1 title\">"+title+"</h5>"+
            "</div>"+
        "</div>"+
    "</div>"+
    "<div class=\""+clsName+" swiper-container slider-nav-style-1\" data-slides-per-view=\"3\">"+
    "<div class=\"new-product-wrapper swiper-wrapper\" >";

var strFooter="</div>"+
"<div class=\"swiper-buttons\">"+
"<div class=\"swiper-button-next\"></div>"+
"<div class=\"swiper-button-prev\"></div>"+
"</div>"+
"</div></div></div>";
for(var i=0;i<lsData.length;i++){
    let hrefUrl="javascript:void(0)";
    if(lsData[i]["col7"]!==""){
        hrefUrl=lsData[i]["col7"];
    }
    var urlTitle= lsData[i]["col1"];
    urlTitle=urlTitle.replace("?","_");
    urlTitle=urlTitle.replace(" ","-");
    strResp+="<div class=\"single-blog swiper-slide\">"+
    "<a href=\""+hrefUrl+"\">"+
    "<img style=\"width:100%\" src=\""+appPath+lsData[i]["col4"]+"\"  alt=\"feature_expo\"/>"+
    "<div class=\"blog-image\">"+
    // "<h6 class=\"central_blog_txt title1\"></h6>"+
    "</div>"+
"</a></div>";
}
return strHeader+strResp+strFooter;
}
export default Image_Slider;