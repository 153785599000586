import $ from 'jquery';
import React from 'react';
import './tab_list.css';
class Account_Opt_Link extends React.Component{
    render(){
        return(<div className="col-lg-12 order-lg-first col-md-12 order-md-last mb-md-60px mb-lm-60px">
        
               
                <div className="sidebar-widget-category center">
                    <ul class="nav nav-tabs justify-content-center scrollmenu">
                        {/* <li><a href="./my_profile" className="selected">My Profile</a></li> */}
                        <li class="nav-item"><a href="../my_orders" className="btn-sm btn-lg btn-primary btn-hover-dark mt-5 nav-link">Recent Orders</a></li>
                        <li class="nav-item"><a href="../my_orders" className="btn-sm btn-lg btn-primary btn-hover-dark mt-5 nav-link">Order History</a></li>
                        <li class="nav-item"><a href="../my_wishlist" className="btn-sm btn-lg btn-primary btn-hover-dark mt-5 nav-link">My Wishlist</a></li>
                        <li class="nav-item"><a href="../my_gift_cards" className="btn-sm btn-lg btn-primary btn-hover-dark mt-5 nav-link">My Gift Cards</a></li>
                        <li class="nav-item"><a href="../my_profile" className="btn-sm btn-lg btn-primary btn-hover-dark mt-5 nav-link">My Profile</a></li>
                        {/* <li><a href="#" className="">Customer Care</a></li> */}
                    </ul>    
                    
                </div>
            </div>)
    }
}
export default Account_Opt_Link;