import React,{useState} from 'react';
export const Comp_Add_New_Address=(props)=>{
    return(<div className="modal fade" id="divAddNewAddress" tabindex="-1" role="dialog">
    
        <div className="modal-dialog" role="document">
            <div className="modal-content">
                <div className="modal-header">
                    
                    <button type="button" className="close" data-bs-dismiss="modal" data-dismiss="modal" aria-label="Close"><span
                            aria-hidden="true">x</span></button>
                </div>
                <div className="modal-body" style={{"justify-content":"center",
    "align-items": "center"}}>
                <div id="shiiping_add" style={{"width":"70%","margin":"auto"}}>
                               
                               <h3>Shipping Address</h3>
                               <form id="frmAddShipAddress" name="frmAddShipAddress">
                               <input type="hidden" name="curl_type" id="curl_type" value="addToUserAddress" />  
                <input type="hidden" name="m_type" id="m_type" value="addToUserAddress" />
                <input type="hidden" name="loc_token" id="loc_token" value={props.compUser[0]["id"]} />
                <input type="hidden" name="res_func" id="res_func" value={btoa("no callbakc")}/>
                <input type="hidden" name="gb_token" id="gb_token" value="5e7hmtL7R0GU7zEBVhJZmrKx8Qb4xy-G_ihGPxqUtrGt5qMHlOaQK8ba1r-o7qKuAChRCIL0_Pilz1gStWW2Mg" />
                               <div className="row">
                                   <div className="col-lg-12 col-md-12">
                                       <div className="billing-info mb-4">
                                           <label>Full Name</label>
                                           <input className="form-control required" id="s_name" name="s_name" type="text" />
                                       </div>
                                   </div>
                                   <div className="col-lg-12 col-md-12">
                                       <div className="billing-info mb-4">
                                           <label>Contact No.</label>
                                           <input className="form-control required" id="s_contact_no" name="s_contact_no" type="number" />
                                       </div>
                                   </div>
                                   <div className="col-lg-12">
                                       <div className="billing-info mb-4">
                                           <label>Address</label>
                                           <input className="billing-address form-control required11" id="s_address1" name="s_address1" placeholder="House number and street name" type="text" />
                                           <input className="form-control" placeholder="Apartment, suite, unit etc." id="s_address2" name="s_address2" type="text" />
                                       </div>
                                   </div>
                                   <div className="col-lg-12">
                                       <div className="billing-select mb-4">
                                           <label>Country</label>
                                           <select className="form-control select_target required" resp_dest="getStateShipping" id="s_country" name="s_country">
                                               <option>Select a country</option>
                                             
                                           </select>
                                       </div>
                                   </div>
                                   <div className="col-lg-12">
                                       <div className="billing-select mb-4">
                                           <label>State</label>
                                           <select className="form-control required" id="s_state" name="s_state">
                                              
                                           </select>
                                       </div>
                                   </div>
                                 
                                   <div className="col-lg-g col-md-6">
                                       <div className="billing-info mb-4">
                                           <label>Town / City</label>
                                           <input className="form-control required" id="s_city" name="s_city" type="text" />
                                       </div>
                                   </div>
                                  
                                   <div className="col-lg-6 col-md-6">
                                       <div className="billing-info mb-4">
                                           <label>ZIP Code</label>
                                           <input className="form-control required cd_input_change111" id="s_pincode" name="s_pincode" type="text" />
                                       </div>
                                   </div>
                                   
                                   
                               </div>
                               <div id="getRegLognNewAdd" style={{color:"red"}}></div>
                            <div className="Place-order mt-25">
                                        <button  className="btn btn-primary btn-outline-dark current-btn float-right _btnSetColor111" onClick={()=>props.addNewAdd()} type="button"><span>Save Address</span></button>
                            </div>
                               </form>
                              
                               </div>
                </div>
            </div>
        </div>
        </div>)

}
export default Comp_Add_New_Address;