import React from 'react';
export const Comp_Gift_OTP=(props)=>{
    let strEmail="";
    if(props.giftOTP.length>0){
        strEmail=props.giftOTP[0]["email_id"].substr(1,6);
        strEmail=props.giftOTP[0]["email_id"].replace(strEmail, "*****");
    }

    //alert(JSON.stringify(props.giftOTP));
    return(<div className="modal fade" id="divGiftOTP" tabindex="-1" role="dialog">
    {typeof props.giftOTP!=="undefined"?
    <div className="modal-dialog" role="document">
        <div className="modal-content">
            <div className="modal-header">
                
                <button type="button" className="close" data-bs-dismiss="modal" data-dismiss="modal" aria-label="Close"><span
                        aria-hidden="true">x</span></button>
            </div>
            <div className="modal-body">
                <div className="row">
                    {/* <div className="col-md-5 col-sm-12 col-xs-12 mb-lm-30px mb-sm-30px">
                        
                        
                      
                    </div> */}
                    <div className="col-md-12 col-sm-12 col-xs-12">
                        <div className="product-details-content quickview-content">
                          {props.giftOTP.length>0?
                            <div className="pricing-meta">
                                <h5>OTP Confirmation </h5>
                               <p>One OTP has sent to {strEmail}</p>
                               <div className="row">
                                <div className="col-md-4">
                                <input className="form-control form-control-sm" id="gift_card_otp" name="gift_card_otp" type="text"/>
                                </div>
                                <div className="col-md-4">
                                    <p id="giftOtpResponse"></p>
                                <button className="btn btn-primary btn-outline-dark current-btn" onClick={()=>props.fnOTPConfirmation()} id="btnLoginEv" name="btnLoginEv" type="button"><span>Confirm OTP</span></button>
                                </div>
                                </div>
                            </div>: <div className="pricing-meta">
                                   {/* <p style={{color:"red"}}>There is no valid gift code detected</p> */}
                            </div>
                            }
                          
                           
                     </div>
                    </div>
                </div>
            </div>
        </div>
    </div>:''}
    </div>)
}
export default Comp_Gift_OTP;