import React from 'react';
import {appGetUrlPath} from '../function_lib/common_cms';
import './top_banner.css';
export function Top_Banner(lsData){
    var appPath=appGetUrlPath();
    var strResp="";
  //  alert(lsData.length);
    for(var i=0;i<lsData.length;i++){

 var stylBgImg="";
 if(lsData[i]["col4"]!==""){
     stylBgImg="style=\"background:url("+appPath+lsData[i]["col4"].trim()+") no-repeat;background-size:cover;background-position:center;height:400px\"";
 }       
strResp+="<div "+stylBgImg+" class=\"breadcrumb-area text-center top-banner\">"+
           "<div class=\"container\">"+
            "<div class=\"row\">"+
                "<div class=\"col-12 align-items-center\">"+
                    "<div class=\"row breadcrumb_box  align-items-center\">"+
                        "<div class=\"col-lg-6 top-title-div  col-md-12 col-sm-12\">"+
                           "<center><h1>"+lsData[i]["col1"]+"</h1></center>"+
                           "<center><h4>"+lsData[i]["col2"]+"</h4></center>"+
                            //  "<ul class=\"breadcrumb-list text-center text-md-right\">"+
                            // "<li class=\"breadcrumb-item\"><a href=\""+"javascript:void(0)"+"\">"+lsData[i]["col1"]+"</a\"></li>"+
                            // "<li class=\"breadcrumb-item\"><a href=\""+"javascript:void(0)"+"\">"+lsData[i]["col2"]+"</a\"></li>"+
                                // "<li class=\"breadcrumb-item\"><a href=\""+lsData[i]["col2"]+"\">"+""+"</a\"></li>"+
                            // "</ul>"+
                        "</div>"+
                    "</div>"+
                "</div>"+
            "</div>"+
        "</div>"+
    "</div>";
    }
    //alert("testing=="+strResp);
    return strResp;
}
export default Top_Banner;