import $ from 'jquery';
import React,{useEffect,useState} from 'react';
import './comp_address_selection.css';
export const Comp_Address_Selection=(props)=>{
  //alert(JSON.stringify(props.lsAddress));

    const [selectedFeatures, setSelectedFeatures] = useState(
        props.lsAddress
      );
    
      const [selectedOption, setSelectedOption] = useState('');
    
      const toggleFeature = (feature) => {
        //alert(feature);
        setSelectedFeatures((prevState) => ({
          ...prevState,
          [feature]: !prevState[feature],
        }));
      };
    
      const selectOption = (option) => {
        getFullAddress(option);
        $("#user_address").val(option);
        setSelectedOption(option);
      };

      const getFullAddress=(option)=>{
        for(let k=0;k<props.lsAddress.length;k++){
          if(props.lsAddress[k]["id"]===atob(option)){
            props.checkShipping(props.lsAddress[k]["pincode"]);
            break;
          }
        }
      }
    
      return (<div class="container container-sm">
        <h3>Address</h3>

        {/* <a href="../my_orders" class="btn btn-lg btn-primary btn-hover-dark mt-5">Add New Address</a> */}
       
        <a href="javascript:void(0)" id="cm_addnew_add" name="cm_addnew_add" data-bs-toggle={"modal"} data-bs-target={"#divAddNewAddress"} data-target={"#divAddNewAddress"} data-toggle={"modal"} class="btn-sm btn-lg btn-primary btn-hover-dark float-right mb-5 mt11s-5">Add New Address</a>
        <div id="divAddressList">
        <span style={{"color":"#992036"}}>select from existing..</span>
        <div className="feature-box">
         
        {props.lsAddress.map((item,index)=>{
          return  <div 
          className={`feature-item ${selectedOption === btoa(item.id) ? 'selected' : ''}`} 
          onClick={() => selectOption(btoa(item.id))}
        >
          {selectedOption===btoa(item.id)?<span className="checkmark">✔</span>:''}
          <input type="radio" id={"adds1"+btoa(item.id)} name="options" value={btoa(item.id)} checked={selectedOption === btoa(item.id)} readOnly />
          <div><b>{item["name"]}</b></div>
          <div><b>{item["mobile_no"]}</b></div>
          <label htmlFor={"adds1"+btoa(item.id)}>{item["address"]}<br></br>
          {item["city"]},{item["pincode"]},
          <br></br>
          {item["state_nm"]},{item["country_nm"]}
          </label>
          
          <div></div>
        </div>
        })}
        </div>
        </div>
        </div>
      );
}
export default Comp_Address_Selection;