import React from 'react';

import ban5 from '../assets/images/banner/5.jpg';
import ban6 from '../assets/images/banner/6.jpg';
import ban7 from '../assets/images/banner/7.jpg';

class Top_Specified extends React.Component{
    render(){
        return(
            <div className="section pb-100px pt-100px">
          <div className="container">
              
              <div className="row">
                 
                  <div className="col-lg-4 col-12 mb-md-30px mb-lm-30px" data-aos="fade-up" data-aos-delay="200">
                      <div className="banner-2">
                          <img src={ban5} alt="" />
                          <div className="info justify-content-start">
                              <div className="content align-self-center">
                                  <h3 className="title">
                                      New Office Chair <br /> Collection
                                  </h3>
                                  <a href="shop-left-sidebar.html" className="shop-link">Shop Now</a>
                              </div>
                          </div>
                      </div>
                  </div>
                 
                  <div className="col-lg-4 col-12 mb-md-30px mb-lm-30px" data-aos="fade-up" data-aos-delay="400">
                      <div className="banner-2">
                          <img src={ban6} alt="" />
                          <div className="info justify-content-start">
                              <div className="content align-self-center">
                                  <h3 className="title">
                                      Living Room <br /> Collection </h3>
                                  <a href="shop-left-sidebar.html" className="shop-link">Shop Now</a>
                              </div>
                          </div>
                      </div>
                  </div>
                 
                  <div className="col-lg-4 col-12" data-aos="fade-up" data-aos-delay="600">
                      <div className="banner-2">
                          <img src={ban7} alt="" />
                          <div className="info justify-content-start">
                              <div className="content align-self-center">
                                  <h3 className="title">
                                      Children Room <br /> Collection
                                  </h3>
                                  <a href="shop-left-sidebar.html" className="shop-link">Shop Now</a>
                              </div>
                          </div>
                      </div>
                  </div>
                 
              </div>
             
          </div>
          </div>
        )
    }
}
export default Top_Specified;