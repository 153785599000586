import $ from 'jquery';
import React from 'react';
import {CallMapMethod,getHmProducts} from '../function_lib/common_lib';


import pImg1 from '../assets/images/product-image/AsthmaticaOil.png';

let loginUser="";
let userObject="";
class Home_Product extends React.Component{
    componentDidMount(){
        alert("i am code");
        var mapCat=[];
        mapCat.push({name:"curl_type",value:"getProducts"});
        mapCat.push({name:"res_func",value:"callBackProList"});
        mapCat.push({name:"gb_token",value:"5e7hmtL7R0GU7zEBVhJZmrKx8Qb4xy-G_ihGPxqUtrGt5qMHlOaQK8ba1r-o7qKuAChRCIL0_Pilz1gStWW2Mg"});
        mapCat.push({name:"p1",value:"new arrivals"});
        mapCat.push({name:"p2",value:"0"});
       // mapCat.push({name:"emp_id",value:userObject[0].id});
        mapCat["res_func"]="callBackProList";
        mapCat["curl_type"]="getProducts";
        CallMapMethod(this,mapCat);
    }
    callBackProList(data,msg){
       
      if(data!=="0"){
       var resPs= getHmProducts(data);
          $("#listHomeProdcut").html(resPs);
      }
    }

    render(){
        return( <div className="section product-tab-area">
        <div className="container">
            <div className="row">
                <div className="col-md-12 text-center" data-aos="fade-up">
                    <div className="section-title mb-0">
                        <h2 className="title">Our Products</h2>
                        <p className="sub-title mb-6">Torem ipsum dolor sit amet, consectetur adipisicing elitsed do eiusmo tempor incididunt ut labore</p>
                    </div>
                </div>
        
               
                <div className="col-md-12 text-center mb-8" data-aos="fade-up" data-aos-delay="200">
                    <ul className="product-tab-nav nav justify-content-center">
                        <li className="nav-item"><a className="nav-link active" data-bs-toggle="tab" href="#tab-product-new-arrivals">New Arrivals</a></li>
                        <li className="nav-item"><a className="nav-link" data-bs-toggle="tab" href="#tab-product-best-sellers">Best
                                Sellers </a></li>
                        <li className="nav-item"><a className="nav-link" data-bs-toggle="tab" href="#tab-product-sale-item">Sale
                                Items</a></li>
                        <li className="nav-item"><a className="nav-link" data-bs-toggle="tab" href="#tab-product-on-sales">On
                                Sales</a></li>
                    </ul>
                </div>
        
            </div>
            <div className="row">
                <div className="col">
                    <div className="tab-content">
                        
                        <div className="tab-pane fade show active" id="tab-product-new-arrivals">
                            <div id="listHomeProdcut" className="row">
                                <div className="col-lg-3 col-md-6 col-sm-6 col-xs-6 mb-6" data-aos="fade-up" data-aos-delay="200">
                                   
                                    <div className="product">
                                        <div className="thumb">
                                            <a href="shop-left-sidebar.html" className="image">
                                                <img src={pImg1} alt="Product" />
                                                <img className="hover-image" src={pImg1} alt="Product" />
                                            </a>
                                            <span className="badges">
                                                <span className="new">New</span>
                                            </span>
                                            <div className="actions">
                                                <a href="wishlist.html" className="action wishlist" title="Wishlist"><i
                                                        className="icon-heart"></i></a>
                                                <a href="#" className="action quickview" data-link-action="quickview" title="Quick view" data-bs-toggle="modal" data-bs-target="#exampleModal"><i
                                                        className="icon-size-fullscreen"></i></a>
                                                <a href="compare.html" className="action compare" title="Compare"><i
                                                        className="icon-refresh"></i></a>
                                            </div>
                                            <button title="Add To Cart" className=" add-to-cart">Add
                                                To Cart</button>
                                        </div>
                                        <div className="content">
                                            <h5 className="title"><a href="shop-left-sidebar.html">Simple minimal Chair </a></h5>
                                            <span className="price">
                                                <span className="new">$38.50</span>
                                            </span>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-3 col-md-6 col-sm-6 col-xs-6 mb-6" data-aos="fade-up" data-aos-delay="400">
                                   
                                    <div className="product">
                                        <div className="thumb">
                                            <a href="shop-left-sidebar.html" className="image">
                                                <img src="assets/images/product-image/3.jpg" alt="Product" />
                                                <img className="hover-image" src="assets/images/product-image/4.jpg" alt="Product" />
                                            </a>
                                            <span className="badges">
                                                <span className="sale">-10%</span>
                                            <span className="new">New</span>
                                            </span>
                                            <div className="actions">
                                                <a href="wishlist.html" className="action wishlist" title="Wishlist"><i
                                                        className="icon-heart"></i></a>
                                                <a href="#" className="action quickview" data-link-action="quickview" title="Quick view" data-bs-toggle="modal" data-bs-target="#exampleModal"><i
                                                        className="icon-size-fullscreen"></i></a>
                                                <a href="compare.html" className="action compare" title="Compare"><i
                                                        className="icon-refresh"></i></a>
                                            </div>
                                            <button title="Add To Cart" className=" add-to-cart">Add
                                                To Cart</button>
                                        </div>
                                        <div className="content">
                                            <h5 className="title"><a href="shop-left-sidebar.html">Wooden decorations</a></h5>
                                            <span className="price">
                                                <span className="new">$38.50</span>
                                            <span className="old">$48.50</span>
                                            </span>
                                        </div>
                                    </div>
                                    
                                </div>
                                <div className="col-lg-3 col-md-6 col-sm-6 col-xs-6 mb-6" data-aos="fade-up" data-aos-delay="600">
                                    
                                    <div className="product">
                                        <div className="thumb">
                                            <a href="shop-left-sidebar.html" className="image">
                                                <img src="assets/images/product-image/5.jpg" alt="Product" />
                                                <img className="hover-image" src="assets/images/product-image/6.jpg" alt="Product" />
                                            </a>
                                            <span className="badges">
                                                <span className="sale">-7%</span>
                                            </span>
                                            <div className="actions">
                                                <a href="wishlist.html" className="action wishlist" title="Wishlist"><i
                                                        className="icon-heart"></i></a>
                                                <a href="#" className="action quickview" data-link-action="quickview" title="Quick view" data-bs-toggle="modal" data-bs-target="#exampleModal"><i
                                                        className="icon-size-fullscreen"></i></a>
                                                <a href="compare.html" className="action compare" title="Compare"><i
                                                        className="icon-refresh"></i></a>
                                            </div>
                                            <button title="Add To Cart" className=" add-to-cart">Add
                                                To Cart</button>
                                        </div>
                                        <div className="content">
                                            <h5 className="title"><a href="shop-left-sidebar.html">High quality vase bottle</a></h5>
                                            <span className="price">
                                                <span className="new">$30.50</span>
                                            <span className="old">$38.00</span>
                                            </span>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-3 col-md-6 col-sm-6 col-xs-6 mb-6" data-aos="fade-up" data-aos-delay="800">
                                    
                                    <div className="product">
                                        <div className="thumb">
                                            <a href="shop-left-sidebar.html" className="image">
                                                <img src="assets/images/product-image/7.jpg" alt="Product" />
                                                <img className="hover-image" src="assets/images/product-image/8.jpg" alt="Product" />
                                            </a>
                                            <span className="badges">
                                                <span className="new">New</span>
                                            </span>
                                            <div className="actions">
                                                <a href="wishlist.html" className="action wishlist" title="Wishlist"><i
                                                        className="icon-heart"></i></a>
                                                <a href="#" className="action quickview" data-link-action="quickview" title="Quick view" data-bs-toggle="modal" data-bs-target="#exampleModal"><i
                                                        className="icon-size-fullscreen"></i></a>
                                                <a href="compare.html" className="action compare" title="Compare"><i
                                                        className="icon-refresh"></i></a>
                                            </div>
                                            <button title="Add To Cart" className=" add-to-cart">Add
                                                To Cart</button>
                                        </div>
                                        <div className="content">
                                            <h5 className="title"><a href="shop-left-sidebar.html">Living & Bedroom Chair</a></h5>
                                            <span className="price">
                                                <span className="new">$38.50</span>
                                            </span>
                                        </div>
                                    </div>
                                    
                                </div>
                                <div className="col-lg-3 col-md-6 col-sm-6 col-xs-6 mb-md-30px mb-lm-30px" data-aos="fade-up" data-aos-delay="200">
                                    
                                    <div className="product">
                                        <div className="thumb">
                                            <a href="shop-left-sidebar.html" className="image">
                                                <img src="assets/images/product-image/9.jpg" alt="Product" />
                                                <img className="hover-image" src="assets/images/product-image/10.jpg" alt="Product" />
                                            </a>
                                            <span className="badges">
                                                <span className="sale">-5%</span>
                                            <span className="new">New</span>
                                            </span>
                                            <div className="actions">
                                                <a href="wishlist.html" className="action wishlist" title="Wishlist"><i
                                                        className="icon-heart"></i></a>
                                                <a href="#" className="action quickview" data-link-action="quickview" title="Quick view" data-bs-toggle="modal" data-bs-target="#exampleModal"><i
                                                        className="icon-size-fullscreen"></i></a>
                                                <a href="compare.html" className="action compare" title="Compare"><i
                                                        className="icon-refresh"></i></a>
                                            </div>
                                            <button title="Add To Cart" className=" add-to-cart">Add
                                                To Cart</button>
                                        </div>
                                        <div className="content">
                                            <h5 className="title"><a href="shop-left-sidebar.html">Living & Bedroom Table</a></h5>
                                            <span className="price">
                                                <span className="new">$38.50</span>
                                            <span className="old">$40.50</span>
                                            </span>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-3 col-md-6 col-sm-6 col-xs-6  mb-md-30px mb-lm-30px" data-aos="fade-up" data-aos-delay="400">
                                    
                                    <div className="product">
                                        <div className="thumb">
                                            <a href="shop-left-sidebar.html" className="image">
                                                <img src="assets/images/product-image/11.jpg" alt="Product" />
                                                <img className="hover-image" src="assets/images/product-image/12.jpg" alt="Product" />
                                            </a>
                                            <span className="badges">
                                            </span>
                                            <div className="actions">
                                                <a href="wishlist.html" className="action wishlist" title="Wishlist"><i
                                                        className="icon-heart"></i></a>
                                                <a href="#" className="action quickview" data-link-action="quickview" title="Quick view" data-bs-toggle="modal" data-bs-target="#exampleModal"><i
                                                        className="icon-size-fullscreen"></i></a>
                                                <a href="compare.html" className="action compare" title="Compare"><i
                                                        className="icon-refresh"></i></a>
                                            </div>
                                            <button title="Add To Cart" className=" add-to-cart">Add
                                                To Cart</button>
                                        </div>
                                        <div className="content">
                                            <h5 className="title"><a href="shop-left-sidebar.html">Wooden decorations</a></h5>
                                            <span className="price">
                                                <span className="new">$38.50</span>
                                            </span>
                                        </div>
                                    </div>
                                    
                                </div>
                                <div className="col-lg-3 col-md-6 col-sm-6 col-xs-6 mb-es-30px" data-aos="fade-up" data-aos-delay="600">
                                   
                                    <div className="product">
                                        <div className="thumb">
                                            <a href="shop-left-sidebar.html" className="image">
                                                <img src="assets/images/product-image/13.jpg" alt="Product" />
                                                <img className="hover-image" src="assets/images/product-image/14.jpg" alt="Product" />
                                            </a>
                                            <span className="badges">
                                            </span>
                                            <div className="actions">
                                                <a href="wishlist.html" className="action wishlist" title="Wishlist"><i
                                                        className="icon-heart"></i></a>
                                                <a href="#" className="action quickview" data-link-action="quickview" title="Quick view" data-bs-toggle="modal" data-bs-target="#exampleModal"><i
                                                        className="icon-size-fullscreen"></i></a>
                                                <a href="compare.html" className="action compare" title="Compare"><i
                                                        className="icon-refresh"></i></a>
                                            </div>
                                            <button title="Add To Cart" className=" add-to-cart">Add
                                                To Cart</button>
                                        </div>
                                        <div className="content">
                                            <h5 className="title"><a href="shop-left-sidebar.html">High quality vase bottle</a></h5>
                                            <span className="price">
                                                <span className="new">$30.50</span>
                                            </span>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-3 col-md-6 col-sm-6 col-xs-6 " data-aos="fade-up" data-aos-delay="800">
                                    
                                    <div className="product">
                                        <div className="thumb">
                                            <a href="shop-left-sidebar.html" className="image">
                                                <img src="assets/images/product-image/15.jpg" alt="Product" />
                                                <img className="hover-image" src="assets/images/product-image/16.jpg" alt="Product" />
                                            </a>
                                            <span className="badges">
                                                <span className="new">New</span>
                                            </span>
                                            <div className="actions">
                                                <a href="wishlist.html" className="action wishlist" title="Wishlist"><i
                                                        className="icon-heart"></i></a>
                                                <a href="#" className="action quickview" data-link-action="quickview" title="Quick view" data-bs-toggle="modal" data-bs-target="#exampleModal"><i
                                                        className="icon-size-fullscreen"></i></a>
                                                <a href="compare.html" className="action compare" title="Compare"><i
                                                        className="icon-refresh"></i></a>
                                            </div>
                                            <button title="Add To Cart" className=" add-to-cart">Add
                                                To Cart</button>
                                        </div>
                                        <div className="content">
                                            <h5 className="title"><a href="shop-left-sidebar.html">Living & Bedroom Chair</a></h5>
                                            <span className="price">
                                                <span className="new">$38.50</span>
                                            </span>
                                        </div>
                                    </div>
                                    
                                </div>
                            </div>
                        </div>
                       
                        <div className="tab-pane fade" id="tab-product-best-sellers">
                            <div className="row">
                                <div className="col-lg-3 col-md-6 col-sm-6 col-xs-6 mb-6" data-aos="fade-up" data-aos-delay="200">
                                   
                                    <div className="product">
                                        <div className="thumb">
                                            <a href="shop-left-sidebar.html" className="image">
                                                <img src="assets/images/product-image/1.jpg" alt="Product" />
                                                <img className="hover-image" src="assets/images/product-image/2.jpg" alt="Product" />
                                            </a>
                                            <span className="badges">
                                                <span className="new">New</span>
                                            </span>
                                            <div className="actions">
                                                <a href="wishlist.html" className="action wishlist" title="Wishlist"><i
                                                        className="icon-heart"></i></a>
                                                <a href="#" className="action quickview" data-link-action="quickview" title="Quick view" data-bs-toggle="modal" data-bs-target="#exampleModal"><i
                                                        className="icon-size-fullscreen"></i></a>
                                                <a href="compare.html" className="action compare" title="Compare"><i
                                                        className="icon-refresh"></i></a>
                                            </div>
                                            <button title="Add To Cart" className=" add-to-cart">Add
                                                To Cart</button>
                                        </div>
                                        <div className="content">
                                            <h5 className="title"><a href="shop-left-sidebar.html">Simple minimal Chair </a></h5>
                                            <span className="price">
                                                <span className="new">$38.50</span>
                                            </span>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-3 col-md-6 col-sm-6 col-xs-6 mb-6" data-aos="fade-up" data-aos-delay="400">
                                    
                                    <div className="product">
                                        <div className="thumb">
                                            <a href="shop-left-sidebar.html" className="image">
                                                <img src="assets/images/product-image/3.jpg" alt="Product" />
                                                <img className="hover-image" src="assets/images/product-image/4.jpg" alt="Product" />
                                            </a>
                                            <span className="badges">
                                                <span className="sale">-10%</span>
                                            <span className="new">New</span>
                                            </span>
                                            <div className="actions">
                                                <a href="wishlist.html" className="action wishlist" title="Wishlist"><i
                                                        className="icon-heart"></i></a>
                                                <a href="#" className="action quickview" data-link-action="quickview" title="Quick view" data-bs-toggle="modal" data-bs-target="#exampleModal"><i
                                                        className="icon-size-fullscreen"></i></a>
                                                <a href="compare.html" className="action compare" title="Compare"><i
                                                        className="icon-refresh"></i></a>
                                            </div>
                                            <button title="Add To Cart" className=" add-to-cart">Add
                                                To Cart</button>
                                        </div>
                                        <div className="content">
                                            <h5 className="title"><a href="shop-left-sidebar.html">Wooden decorations</a></h5>
                                            <span className="price">
                                                <span className="new">$38.50</span>
                                            <span className="old">$48.50</span>
                                            </span>
                                        </div>
                                    </div>
                                    
                                </div>
                                <div className="col-lg-3 col-md-6 col-sm-6 col-xs-6 mb-6" data-aos="fade-up" data-aos-delay="600">
                                    
                                    <div className="product">
                                        <div className="thumb">
                                            <a href="shop-left-sidebar.html" className="image">
                                                <img src="assets/images/product-image/5.jpg" alt="Product" />
                                                <img className="hover-image" src="assets/images/product-image/6.jpg" alt="Product" />
                                            </a>
                                            <span className="badges">
                                                <span className="sale">-7%</span>
                                            </span>
                                            <div className="actions">
                                                <a href="wishlist.html" className="action wishlist" title="Wishlist"><i
                                                        className="icon-heart"></i></a>
                                                <a href="#" className="action quickview" data-link-action="quickview" title="Quick view" data-bs-toggle="modal" data-bs-target="#exampleModal"><i
                                                        className="icon-size-fullscreen"></i></a>
                                                <a href="compare.html" className="action compare" title="Compare"><i
                                                        className="icon-refresh"></i></a>
                                            </div>
                                            <button title="Add To Cart" className=" add-to-cart">Add
                                                To Cart</button>
                                        </div>
                                        <div className="content">
                                            <h5 className="title"><a href="shop-left-sidebar.html">High quality vase bottle</a></h5>
                                            <span className="price">
                                                <span className="new">$30.50</span>
                                            <span className="old">$38.00</span>
                                            </span>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-3 col-md-6 col-sm-6 col-xs-6 mb-6" data-aos="fade-up" data-aos-delay="800">
                                   
                                    <div className="product">
                                        <div className="thumb">
                                            <a href="shop-left-sidebar.html" className="image">
                                                <img src="assets/images/product-image/7.jpg" alt="Product" />
                                                <img className="hover-image" src="assets/images/product-image/8.jpg" alt="Product" />
                                            </a>
                                            <span className="badges">
                                                <span className="new">New</span>
                                            </span>
                                            <div className="actions">
                                                <a href="wishlist.html" className="action wishlist" title="Wishlist"><i
                                                        className="icon-heart"></i></a>
                                                <a href="#" className="action quickview" data-link-action="quickview" title="Quick view" data-bs-toggle="modal" data-bs-target="#exampleModal"><i
                                                        className="icon-size-fullscreen"></i></a>
                                                <a href="compare.html" className="action compare" title="Compare"><i
                                                        className="icon-refresh"></i></a>
                                            </div>
                                            <button title="Add To Cart" className=" add-to-cart">Add
                                                To Cart</button>
                                        </div>
                                        <div className="content">
                                            <h5 className="title"><a href="shop-left-sidebar.html">Living & Bedroom Chair</a></h5>
                                            <span className="price">
                                                <span className="new">$38.50</span>
                                            </span>
                                        </div>
                                    </div>
                                    
                                </div>
                                <div className="col-lg-3 col-md-6 col-sm-6 col-xs-6 mb-md-30px mb-lm-30px" data-aos="fade-up" data-aos-delay="200">
                                    
                                    <div className="product">
                                        <div className="thumb">
                                            <a href="shop-left-sidebar.html" className="image">
                                                <img src="assets/images/product-image/9.jpg" alt="Product" />
                                                <img className="hover-image" src="assets/images/product-image/10.jpg" alt="Product" />
                                            </a>
                                            <span className="badges">
                                                <span className="sale">-5%</span>
                                            <span className="new">New</span>
                                            </span>
                                            <div className="actions">
                                                <a href="wishlist.html" className="action wishlist" title="Wishlist"><i
                                                        className="icon-heart"></i></a>
                                                <a href="#" className="action quickview" data-link-action="quickview" title="Quick view" data-bs-toggle="modal" data-bs-target="#exampleModal"><i
                                                        className="icon-size-fullscreen"></i></a>
                                                <a href="compare.html" className="action compare" title="Compare"><i
                                                        className="icon-refresh"></i></a>
                                            </div>
                                            <button title="Add To Cart" className=" add-to-cart">Add
                                                To Cart</button>
                                        </div>
                                        <div className="content">
                                            <h5 className="title"><a href="shop-left-sidebar.html">Living & Bedroom Table</a></h5>
                                            <span className="price">
                                                <span className="new">$38.50</span>
                                            <span className="old">$40.50</span>
                                            </span>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-3 col-md-6 col-sm-6 col-xs-6  mb-md-30px mb-lm-30px" data-aos="fade-up" data-aos-delay="400">
                                    
                                    <div className="product">
                                        <div className="thumb">
                                            <a href="shop-left-sidebar.html" className="image">
                                                <img src="assets/images/product-image/11.jpg" alt="Product" />
                                                <img className="hover-image" src="assets/images/product-image/12.jpg" alt="Product" />
                                            </a>
                                            <span className="badges">
                                            </span>
                                            <div className="actions">
                                                <a href="wishlist.html" className="action wishlist" title="Wishlist"><i
                                                        className="icon-heart"></i></a>
                                                <a href="#" className="action quickview" data-link-action="quickview" title="Quick view" data-bs-toggle="modal" data-bs-target="#exampleModal"><i
                                                        className="icon-size-fullscreen"></i></a>
                                                <a href="compare.html" className="action compare" title="Compare"><i
                                                        className="icon-refresh"></i></a>
                                            </div>
                                            <button title="Add To Cart" className=" add-to-cart">Add
                                                To Cart</button>
                                        </div>
                                        <div className="content">
                                            <h5 className="title"><a href="shop-left-sidebar.html">Wooden decorations</a></h5>
                                            <span className="price">
                                                <span className="new">$38.50</span>
                                            </span>
                                        </div>
                                    </div>
                                    
                                </div>
                                <div className="col-lg-3 col-md-6 col-sm-6 col-xs-6 mb-es-30px" data-aos="fade-up" data-aos-delay="600">
                                    
                                    <div className="product">
                                        <div className="thumb">
                                            <a href="shop-left-sidebar.html" className="image">
                                                <img src="assets/images/product-image/13.jpg" alt="Product" />
                                                <img className="hover-image" src="assets/images/product-image/14.jpg" alt="Product" />
                                            </a>
                                            <span className="badges">
                                            </span>
                                            <div className="actions">
                                                <a href="wishlist.html" className="action wishlist" title="Wishlist"><i
                                                        className="icon-heart"></i></a>
                                                <a href="#" className="action quickview" data-link-action="quickview" title="Quick view" data-bs-toggle="modal" data-bs-target="#exampleModal"><i
                                                        className="icon-size-fullscreen"></i></a>
                                                <a href="compare.html" className="action compare" title="Compare"><i
                                                        className="icon-refresh"></i></a>
                                            </div>
                                            <button title="Add To Cart" className=" add-to-cart">Add
                                                To Cart</button>
                                        </div>
                                        <div className="content">
                                            <h5 className="title"><a href="shop-left-sidebar.html">High quality vase bottle</a></h5>
                                            <span className="price">
                                                <span className="new">$30.50</span>
                                            </span>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-3 col-md-6 col-sm-6 col-xs-6 " data-aos="fade-up" data-aos-delay="800">
                                   
                                    <div className="product">
                                        <div className="thumb">
                                            <a href="shop-left-sidebar.html" className="image">
                                                <img src="assets/images/product-image/15.jpg" alt="Product" />
                                                <img className="hover-image" src="assets/images/product-image/16.jpg" alt="Product" />
                                            </a>
                                            <span className="badges">
                                                <span className="new">New</span>
                                            </span>
                                            <div className="actions">
                                                <a href="wishlist.html" className="action wishlist" title="Wishlist"><i
                                                        className="icon-heart"></i></a>
                                                <a href="#" className="action quickview" data-link-action="quickview" title="Quick view" data-bs-toggle="modal" data-bs-target="#exampleModal"><i
                                                        className="icon-size-fullscreen"></i></a>
                                                <a href="compare.html" className="action compare" title="Compare"><i
                                                        className="icon-refresh"></i></a>
                                            </div>
                                            <button title="Add To Cart" className=" add-to-cart">Add
                                                To Cart</button>
                                        </div>
                                        <div className="content">
                                            <h5 className="title"><a href="shop-left-sidebar.html">Living & Bedroom Chair</a></h5>
                                            <span className="price">
                                                <span className="new">$38.50</span>
                                            </span>
                                        </div>
                                    </div>
                                   
                                </div>
                            </div>
                        </div>
                        
                        <div className="tab-pane fade" id="tab-product-sale-item">
                            <div className="row">
                                <div className="col-lg-3 col-md-6 col-sm-6 col-xs-6 mb-6" data-aos="fade-up" data-aos-delay="200">
                                    
                                    <div className="product">
                                        <div className="thumb">
                                            <a href="shop-left-sidebar.html" className="image">
                                                <img src="assets/images/product-image/1.jpg" alt="Product" />
                                                <img className="hover-image" src="assets/images/product-image/2.jpg" alt="Product" />
                                            </a>
                                            <span className="badges">
                                                <span className="new">New</span>
                                            </span>
                                            <div className="actions">
                                                <a href="wishlist.html" className="action wishlist" title="Wishlist"><i
                                                        className="icon-heart"></i></a>
                                                <a href="#" className="action quickview" data-link-action="quickview" title="Quick view" data-bs-toggle="modal" data-bs-target="#exampleModal"><i
                                                        className="icon-size-fullscreen"></i></a>
                                                <a href="compare.html" className="action compare" title="Compare"><i
                                                        className="icon-refresh"></i></a>
                                            </div>
                                            <button title="Add To Cart" className=" add-to-cart">Add
                                                To Cart</button>
                                        </div>
                                        <div className="content">
                                            <h5 className="title"><a href="shop-left-sidebar.html">Simple minimal Chair </a></h5>
                                            <span className="price">
                                                <span className="new">$38.50</span>
                                            </span>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-3 col-md-6 col-sm-6 col-xs-6 mb-6" data-aos="fade-up" data-aos-delay="400">
                                    
                                    <div className="product">
                                        <div className="thumb">
                                            <a href="shop-left-sidebar.html" className="image">
                                                <img src="assets/images/product-image/3.jpg" alt="Product" />
                                                <img className="hover-image" src="assets/images/product-image/4.jpg" alt="Product" />
                                            </a>
                                            <span className="badges">
                                                <span className="sale">-10%</span>
                                            <span className="new">New</span>
                                            </span>
                                            <div className="actions">
                                                <a href="wishlist.html" className="action wishlist" title="Wishlist"><i
                                                        className="icon-heart"></i></a>
                                                <a href="#" className="action quickview" data-link-action="quickview" title="Quick view" data-bs-toggle="modal" data-bs-target="#exampleModal"><i
                                                        className="icon-size-fullscreen"></i></a>
                                                <a href="compare.html" className="action compare" title="Compare"><i
                                                        className="icon-refresh"></i></a>
                                            </div>
                                            <button title="Add To Cart" className=" add-to-cart">Add
                                                To Cart</button>
                                        </div>
                                        <div className="content">
                                            <h5 className="title"><a href="shop-left-sidebar.html">Wooden decorations</a></h5>
                                            <span className="price">
                                                <span className="new">$38.50</span>
                                            <span className="old">$48.50</span>
                                            </span>
                                        </div>
                                    </div>
                                    
                                </div>
                                <div className="col-lg-3 col-md-6 col-sm-6 col-xs-6 mb-6" data-aos="fade-up" data-aos-delay="600">
                                    
                                    <div className="product">
                                        <div className="thumb">
                                            <a href="shop-left-sidebar.html" className="image">
                                                <img src="assets/images/product-image/5.jpg" alt="Product" />
                                                <img className="hover-image" src="assets/images/product-image/6.jpg" alt="Product" />
                                            </a>
                                            <span className="badges">
                                                <span className="sale">-7%</span>
                                            </span>
                                            <div className="actions">
                                                <a href="wishlist.html" className="action wishlist" title="Wishlist"><i
                                                        className="icon-heart"></i></a>
                                                <a href="#" className="action quickview" data-link-action="quickview" title="Quick view" data-bs-toggle="modal" data-bs-target="#exampleModal"><i
                                                        className="icon-size-fullscreen"></i></a>
                                                <a href="compare.html" className="action compare" title="Compare"><i
                                                        className="icon-refresh"></i></a>
                                            </div>
                                            <button title="Add To Cart" className=" add-to-cart">Add
                                                To Cart</button>
                                        </div>
                                        <div className="content">
                                            <h5 className="title"><a href="shop-left-sidebar.html">High quality vase bottle</a></h5>
                                            <span className="price">
                                                <span className="new">$30.50</span>
                                            <span className="old">$38.00</span>
                                            </span>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-3 col-md-6 col-sm-6 col-xs-6 mb-6" data-aos="fade-up" data-aos-delay="800">
                                    
                                    <div className="product">
                                        <div className="thumb">
                                            <a href="shop-left-sidebar.html" className="image">
                                                <img src="assets/images/product-image/7.jpg" alt="Product" />
                                                <img className="hover-image" src="assets/images/product-image/8.jpg" alt="Product" />
                                            </a>
                                            <span className="badges">
                                                <span className="new">New</span>
                                            </span>
                                            <div className="actions">
                                                <a href="wishlist.html" className="action wishlist" title="Wishlist"><i
                                                        className="icon-heart"></i></a>
                                                <a href="#" className="action quickview" data-link-action="quickview" title="Quick view" data-bs-toggle="modal" data-bs-target="#exampleModal"><i
                                                        className="icon-size-fullscreen"></i></a>
                                                <a href="compare.html" className="action compare" title="Compare"><i
                                                        className="icon-refresh"></i></a>
                                            </div>
                                            <button title="Add To Cart" className=" add-to-cart">Add
                                                To Cart</button>
                                        </div>
                                        <div className="content">
                                            <h5 className="title"><a href="shop-left-sidebar.html">Living & Bedroom Chair</a></h5>
                                            <span className="price">
                                                <span className="new">$38.50</span>
                                            </span>
                                        </div>
                                    </div>
                                    
                                </div>
                                <div className="col-lg-3 col-md-6 col-sm-6 col-xs-6 mb-md-30px mb-lm-30px" data-aos="fade-up" data-aos-delay="200">
                                    
                                    <div className="product">
                                        <div className="thumb">
                                            <a href="shop-left-sidebar.html" className="image">
                                                <img src="assets/images/product-image/9.jpg" alt="Product" />
                                                <img className="hover-image" src="assets/images/product-image/10.jpg" alt="Product" />
                                            </a>
                                            <span className="badges">
                                                <span className="sale">-5%</span>
                                            <span className="new">New</span>
                                            </span>
                                            <div className="actions">
                                                <a href="wishlist.html" className="action wishlist" title="Wishlist"><i
                                                        className="icon-heart"></i></a>
                                                <a href="#" className="action quickview" data-link-action="quickview" title="Quick view" data-bs-toggle="modal" data-bs-target="#exampleModal"><i
                                                        className="icon-size-fullscreen"></i></a>
                                                <a href="compare.html" className="action compare" title="Compare"><i
                                                        className="icon-refresh"></i></a>
                                            </div>
                                            <button title="Add To Cart" className=" add-to-cart">Add
                                                To Cart</button>
                                        </div>
                                        <div className="content">
                                            <h5 className="title"><a href="shop-left-sidebar.html">Living & Bedroom Table</a></h5>
                                            <span className="price">
                                                <span className="new">$38.50</span>
                                            <span className="old">$40.50</span>
                                            </span>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-3 col-md-6 col-sm-6 col-xs-6  mb-md-30px mb-lm-30px" data-aos="fade-up" data-aos-delay="400">
                                    
                                    <div className="product">
                                        <div className="thumb">
                                            <a href="shop-left-sidebar.html" className="image">
                                                <img src="assets/images/product-image/11.jpg" alt="Product" />
                                                <img className="hover-image" src="assets/images/product-image/12.jpg" alt="Product" />
                                            </a>
                                            <span className="badges">
                                            </span>
                                            <div className="actions">
                                                <a href="wishlist.html" className="action wishlist" title="Wishlist"><i
                                                        className="icon-heart"></i></a>
                                                <a href="#" className="action quickview" data-link-action="quickview" title="Quick view" data-bs-toggle="modal" data-bs-target="#exampleModal"><i
                                                        className="icon-size-fullscreen"></i></a>
                                                <a href="compare.html" className="action compare" title="Compare"><i
                                                        className="icon-refresh"></i></a>
                                            </div>
                                            <button title="Add To Cart" className=" add-to-cart">Add
                                                To Cart</button>
                                        </div>
                                        <div className="content">
                                            <h5 className="title"><a href="shop-left-sidebar.html">Wooden decorations</a></h5>
                                            <span className="price">
                                                <span className="new">$38.50</span>
                                            </span>
                                        </div>
                                    </div>
                                    
                                </div>
                                <div className="col-lg-3 col-md-6 col-sm-6 col-xs-6 mb-es-30px" data-aos="fade-up" data-aos-delay="600">
                                    
                                    <div className="product">
                                        <div className="thumb">
                                            <a href="shop-left-sidebar.html" className="image">
                                                <img src="assets/images/product-image/13.jpg" alt="Product" />
                                                <img className="hover-image" src="assets/images/product-image/14.jpg" alt="Product" />
                                            </a>
                                            <span className="badges">
                                            </span>
                                            <div className="actions">
                                                <a href="wishlist.html" className="action wishlist" title="Wishlist"><i
                                                        className="icon-heart"></i></a>
                                                <a href="#" className="action quickview" data-link-action="quickview" title="Quick view" data-bs-toggle="modal" data-bs-target="#exampleModal"><i
                                                        className="icon-size-fullscreen"></i></a>
                                                <a href="compare.html" className="action compare" title="Compare"><i
                                                        className="icon-refresh"></i></a>
                                            </div>
                                            <button title="Add To Cart" className=" add-to-cart">Add
                                                To Cart</button>
                                        </div>
                                        <div className="content">
                                            <h5 className="title"><a href="shop-left-sidebar.html">High quality vase bottle</a></h5>
                                            <span className="price">
                                                <span className="new">$30.50</span>
                                            </span>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-3 col-md-6 col-sm-6 col-xs-6 " data-aos="fade-up" data-aos-delay="800">
                                    
                                    <div className="product">
                                        <div className="thumb">
                                            <a href="shop-left-sidebar.html" className="image">
                                                <img src="assets/images/product-image/15.jpg" alt="Product" />
                                                <img className="hover-image" src="assets/images/product-image/16.jpg" alt="Product" />
                                            </a>
                                            <span className="badges">
                                                <span className="new">New</span>
                                            </span>
                                            <div className="actions">
                                                <a href="wishlist.html" className="action wishlist" title="Wishlist"><i
                                                        className="icon-heart"></i></a>
                                                <a href="#" className="action quickview" data-link-action="quickview" title="Quick view" data-bs-toggle="modal" data-bs-target="#exampleModal"><i
                                                        className="icon-size-fullscreen"></i></a>
                                                <a href="compare.html" className="action compare" title="Compare"><i
                                                        className="icon-refresh"></i></a>
                                            </div>
                                            <button title="Add To Cart" className=" add-to-cart">Add
                                                To Cart</button>
                                        </div>
                                        <div className="content">
                                            <h5 className="title"><a href="shop-left-sidebar.html">Living & Bedroom Chair</a></h5>
                                            <span className="price">
                                                <span className="new">$38.50</span>
                                            </span>
                                        </div>
                                    </div>
                                   
                                </div>
                            </div>
                        </div>
                        
                        <div className="tab-pane fade" id="tab-product-on-sales">
                            <div className="row">
                                <div className="col-lg-3 col-md-6 col-sm-6 col-xs-6 mb-6" data-aos="fade-up" data-aos-delay="200">
                                   
                                    <div className="product">
                                        <div className="thumb">
                                            <a href="shop-left-sidebar.html" className="image">
                                                <img src="assets/images/product-image/1.jpg" alt="Product" />
                                                <img className="hover-image" src="assets/images/product-image/2.jpg" alt="Product" />
                                            </a>
                                            <span className="badges">
                                                <span className="new">New</span>
                                            </span>
                                            <div className="actions">
                                                <a href="wishlist.html" className="action wishlist" title="Wishlist"><i
                                                        className="icon-heart"></i></a>
                                                <a href="#" className="action quickview" data-link-action="quickview" title="Quick view" data-bs-toggle="modal" data-bs-target="#exampleModal"><i
                                                        className="icon-size-fullscreen"></i></a>
                                                <a href="compare.html" className="action compare" title="Compare"><i
                                                        className="icon-refresh"></i></a>
                                            </div>
                                            <button title="Add To Cart" className=" add-to-cart">Add
                                                To Cart</button>
                                        </div>
                                        <div className="content">
                                            <h5 className="title"><a href="shop-left-sidebar.html">Simple minimal Chair </a></h5>
                                            <span className="price">
                                                <span className="new">$38.50</span>
                                            </span>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-3 col-md-6 col-sm-6 col-xs-6 mb-6" data-aos="fade-up" data-aos-delay="400">
                                    
                                    <div className="product">
                                        <div className="thumb">
                                            <a href="shop-left-sidebar.html" className="image">
                                                <img src="assets/images/product-image/3.jpg" alt="Product" />
                                                <img className="hover-image" src="assets/images/product-image/4.jpg" alt="Product" />
                                            </a>
                                            <span className="badges">
                                                <span className="sale">-10%</span>
                                            <span className="new">New</span>
                                            </span>
                                            <div className="actions">
                                                <a href="wishlist.html" className="action wishlist" title="Wishlist"><i
                                                        className="icon-heart"></i></a>
                                                <a href="#" className="action quickview" data-link-action="quickview" title="Quick view" data-bs-toggle="modal" data-bs-target="#exampleModal"><i
                                                        className="icon-size-fullscreen"></i></a>
                                                <a href="compare.html" className="action compare" title="Compare"><i
                                                        className="icon-refresh"></i></a>
                                            </div>
                                            <button title="Add To Cart" className=" add-to-cart">Add
                                                To Cart</button>
                                        </div>
                                        <div className="content">
                                            <h5 className="title"><a href="shop-left-sidebar.html">Wooden decorations</a></h5>
                                            <span className="price">
                                                <span className="new">$38.50</span>
                                            <span className="old">$48.50</span>
                                            </span>
                                        </div>
                                    </div>
                                    
                                </div>
                                <div className="col-lg-3 col-md-6 col-sm-6 col-xs-6 mb-6" data-aos="fade-up" data-aos-delay="600">
                                    
                                    <div className="product">
                                        <div className="thumb">
                                            <a href="shop-left-sidebar.html" className="image">
                                                <img src="assets/images/product-image/5.jpg" alt="Product" />
                                                <img className="hover-image" src="assets/images/product-image/6.jpg" alt="Product" />
                                            </a>
                                            <span className="badges">
                                                <span className="sale">-7%</span>
                                            </span>
                                            <div className="actions">
                                                <a href="wishlist.html" className="action wishlist" title="Wishlist"><i
                                                        className="icon-heart"></i></a>
                                                <a href="#" className="action quickview" data-link-action="quickview" title="Quick view" data-bs-toggle="modal" data-bs-target="#exampleModal"><i
                                                        className="icon-size-fullscreen"></i></a>
                                                <a href="compare.html" className="action compare" title="Compare"><i
                                                        className="icon-refresh"></i></a>
                                            </div>
                                            <button title="Add To Cart" className=" add-to-cart">Add
                                                To Cart</button>
                                        </div>
                                        <div className="content">
                                            <h5 className="title"><a href="shop-left-sidebar.html">High quality vase bottle</a></h5>
                                            <span className="price">
                                                <span className="new">$30.50</span>
                                            <span className="old">$38.00</span>
                                            </span>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-3 col-md-6 col-sm-6 col-xs-6 mb-6" data-aos="fade-up" data-aos-delay="800">
                                    
                                    <div className="product">
                                        <div className="thumb">
                                            <a href="shop-left-sidebar.html" className="image">
                                                <img src="assets/images/product-image/7.jpg" alt="Product" />
                                                <img className="hover-image" src="assets/images/product-image/8.jpg" alt="Product" />
                                            </a>
                                            <span className="badges">
                                                <span className="new">New</span>
                                            </span>
                                            <div className="actions">
                                                <a href="wishlist.html" className="action wishlist" title="Wishlist"><i
                                                        className="icon-heart"></i></a>
                                                <a href="#" className="action quickview" data-link-action="quickview" title="Quick view" data-bs-toggle="modal" data-bs-target="#exampleModal"><i
                                                        className="icon-size-fullscreen"></i></a>
                                                <a href="compare.html" className="action compare" title="Compare"><i
                                                        className="icon-refresh"></i></a>
                                            </div>
                                            <button title="Add To Cart" className=" add-to-cart">Add
                                                To Cart</button>
                                        </div>
                                        <div className="content">
                                            <h5 className="title"><a href="shop-left-sidebar.html">Living & Bedroom Chair</a></h5>
                                            <span className="price">
                                                <span className="new">$38.50</span>
                                            </span>
                                        </div>
                                    </div>
                                    
                                </div>
                                <div className="col-lg-3 col-md-6 col-sm-6 col-xs-6 mb-md-30px mb-lm-30px" data-aos="fade-up" data-aos-delay="200">
                                    
                                    <div className="product">
                                        <div className="thumb">
                                            <a href="shop-left-sidebar.html" className="image">
                                                <img src="assets/images/product-image/9.jpg" alt="Product" />
                                                <img className="hover-image" src="assets/images/product-image/10.jpg" alt="Product" />
                                            </a>
                                            <span className="badges">
                                                <span className="sale">-5%</span>
                                            <span className="new">New</span>
                                            </span>
                                            <div className="actions">
                                                <a href="wishlist.html" className="action wishlist" title="Wishlist"><i
                                                        className="icon-heart"></i></a>
                                                <a href="#" className="action quickview" data-link-action="quickview" title="Quick view" data-bs-toggle="modal" data-bs-target="#exampleModal"><i
                                                        className="icon-size-fullscreen"></i></a>
                                                <a href="compare.html" className="action compare" title="Compare"><i
                                                        className="icon-refresh"></i></a>
                                            </div>
                                            <button title="Add To Cart" className=" add-to-cart">Add
                                                To Cart</button>
                                        </div>
                                        <div className="content">
                                            <h5 className="title"><a href="shop-left-sidebar.html">Living & Bedroom Table</a></h5>
                                            <span className="price">
                                                <span className="new">$38.50</span>
                                            <span className="old">$40.50</span>
                                            </span>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-3 col-md-6 col-sm-6 col-xs-6  mb-md-30px mb-lm-30px" data-aos="fade-up" data-aos-delay="400">
                                    
                                    <div className="product">
                                        <div className="thumb">
                                            <a href="shop-left-sidebar.html" className="image">
                                                <img src="assets/images/product-image/11.jpg" alt="Product" />
                                                <img className="hover-image" src="assets/images/product-image/12.jpg" alt="Product" />
                                            </a>
                                            <span className="badges">
                                            </span>
                                            <div className="actions">
                                                <a href="wishlist.html" className="action wishlist" title="Wishlist"><i
                                                        className="icon-heart"></i></a>
                                                <a href="#" className="action quickview" data-link-action="quickview" title="Quick view" data-bs-toggle="modal" data-bs-target="#exampleModal"><i
                                                        className="icon-size-fullscreen"></i></a>
                                                <a href="compare.html" className="action compare" title="Compare"><i
                                                        className="icon-refresh"></i></a>
                                            </div>
                                            <button title="Add To Cart" className=" add-to-cart">Add
                                                To Cart</button>
                                        </div>
                                        <div className="content">
                                            <h5 className="title"><a href="shop-left-sidebar.html">Wooden decorations</a></h5>
                                            <span className="price">
                                                <span className="new">$38.50</span>
                                            </span>
                                        </div>
                                    </div>
                                    
                                </div>
                                <div className="col-lg-3 col-md-6 col-sm-6 col-xs-6 mb-es-30px" data-aos="fade-up" data-aos-delay="600">
                                    
                                    <div className="product">
                                        <div className="thumb">
                                            <a href="shop-left-sidebar.html" className="image">
                                                <img src="assets/images/product-image/13.jpg" alt="Product" />
                                                <img className="hover-image" src="assets/images/product-image/14.jpg" alt="Product" />
                                            </a>
                                            <span className="badges">
                                            </span>
                                            <div className="actions">
                                                <a href="wishlist.html" className="action wishlist" title="Wishlist"><i
                                                        className="icon-heart"></i></a>
                                                <a href="#" className="action quickview" data-link-action="quickview" title="Quick view" data-bs-toggle="modal" data-bs-target="#exampleModal"><i
                                                        className="icon-size-fullscreen"></i></a>
                                                <a href="compare.html" className="action compare" title="Compare"><i
                                                        className="icon-refresh"></i></a>
                                            </div>
                                            <button title="Add To Cart" className=" add-to-cart">Add
                                                To Cart</button>
                                        </div>
                                        <div className="content">
                                            <h5 className="title"><a href="shop-left-sidebar.html">High quality vase bottle</a></h5>
                                            <span className="price">
                                                <span className="new">$30.50</span>
                                            </span>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-3 col-md-6 col-sm-6 col-xs-6 " data-aos="fade-up" data-aos-delay="800">
                                   
                                    <div className="product">
                                        <div className="thumb">
                                            <a href="shop-left-sidebar.html" className="image">
                                                <img src="assets/images/product-image/15.jpg" alt="Product" />
                                                <img className="hover-image" src="assets/images/product-image/16.jpg" alt="Product" />
                                            </a>
                                            <span className="badges">
                                                <span className="new">New</span>
                                            </span>
                                            <div className="actions">
                                                <a href="wishlist.html" className="action wishlist" title="Wishlist"><i
                                                        className="icon-heart"></i></a>
                                                <a href="#" className="action quickview" data-link-action="quickview" title="Quick view" data-bs-toggle="modal" data-bs-target="#exampleModal"><i
                                                        className="icon-size-fullscreen"></i></a>
                                                <a href="compare.html" className="action compare" title="Compare"><i
                                                        className="icon-refresh"></i></a>
                                            </div>
                                            <button title="Add To Cart" className=" add-to-cart">Add
                                                To Cart</button>
                                        </div>
                                        <div className="content">
                                            <h5 className="title"><a href="shop-left-sidebar.html">Living & Bedroom Chair</a></h5>
                                            <span className="price">
                                                <span className="new">$38.50</span>
                                            </span>
                                        </div>
                                    </div>
                                    
                                </div>
                            </div>
                        </div>
                        
                    </div>
                </div>
            </div>
        </div>
        </div>)
    }
}
export default Home_Product;