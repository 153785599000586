import React from 'react';
import {sortList} from '../function_lib/common_lib';
export const Comp_Shipping_Selection=(props)=>{
    //alert(JSON.stringify(props));
    let customlistShipping=[];

    let propShippingData=[];
    for(let p=0;p<props.shipping_data.length;p++){
        if(props.shipping_data[p]["retail_rate"]!=="" && props.shipping_data[p]["retail_rate"]!==null){
            //alert(props.shipping_data[p]["retail_rate"]);
            propShippingData.push(props.shipping_data[p]);
        }
    }
    let lsCheck=sortList(propShippingData,"retail_rate",true,true);

     //find store pickup if not pushed
     let f=0
    
     for(f=0;f<customlistShipping.length;f++){
         if(customlistShipping[f]["service"]==="Store Pickup"){
            // alert("i m in condi"+customlistShipping[f]["service"]);
             break;
         }
     }
    
     if(f===customlistShipping.length){
         customlistShipping.push({"carrier":"","service":"Store Pickup","retail_rate":"0.00","carrier_account_id":"0"});
     }

     
    //alert(JSON.stringify(lsCheck))
    if(lsCheck.length>5){
        for(let i=0;i<lsCheck.length;i++){
            customlistShipping.push(lsCheck[i]);
            if(i==2){
                break;
            }
        }
        let countMax=0;
        for(let i=lsCheck.length-2;i<lsCheck.length;i++){
            customlistShipping.push(lsCheck[i]);
            countMax++
            if(countMax==2){
                break;
            }
        }
    }else{
        for(let i=0;i<lsCheck.length;i++){
            customlistShipping.push(lsCheck[i]);
        }
        
    }
    
  return(<div>
    {props.shipping_data.length>0?
    <div class="col-sm-12 col-md-12 col-xs-12 panel panel-default no-border single-my-account">
        <div class="panel-heading my-account-title">
            <h3 class="panel-title"><a data-bs-toggle="collapse" href="#my-account-940" class="collapsed" aria-expanded="true">Select Courier Service</a></h3></div>
            <div id="my-account-940" class="panel-collapse collapsed collapse show" data-bs-parent="#faq">
                <div class="panel-body">
                <table>
    
    <tr><td></td><td><b>Type</b></td><td><b>Rate</b></td><td><b>Delivery</b></td></tr>
    {customlistShipping.map((item,index)=>{
        //alert(item["delivery_days"]);
        return <tr>
            <td><input className="_setbackparam" param1={item["retail_rate"]} param2={item["carrier_account_id"]+","+item["carrier"]+"-"+item["service"]+","+item["retail_rate"]} type="radio" name="rdShipping" value={item["carrier_account_id"]+","+item["carrier"]+"-"+item["service"]+","+item["retail_rate"]} /></td>
            <td>{item["carrier"]+"-"+item["service"]}</td>
            <td>{item["retail_rate"]}</td>
            <td>{(item["delivery_days"]!==null && typeof item["delivery_days"]!=="undefined")?item["delivery_days"]+" Days":'-'}</td>
        </tr>
    })}
  </table>


</div></div>
</div>:''}
    
    </div>)
}
export default Comp_Shipping_Selection;