import React from 'react';
import {appGetUrlPath} from '../function_lib/common_lib';
// import './video_slider.css';
export function Video_Slider(lsData){
/*Header Values */
var appUrtPath=appGetUrlPath();
var title="";
if(lsData.length!=0){
title=lsData[0]["g_title"];
}

    var appPath=appGetUrlPath();
//var listData=JSON.parse(lsData);
var strResp="";


var strHeader="<div class=\"main-blog-area pt-50px pb-50px\">"+
"<div class=\"container\">"+
    "<div class=\"row\">"+
        "<div class=\"col-md-12\">"+
            "<div class=\"heading page__title-bar\">"+
                "<h3 class=\"title\">"+title+"</h3>"+
            "</div>"+
        "</div>"+
    "</div>"+
    "<div class=\"new-product-slider swiper-container slider-nav-style-1\">"+
    "<div class=\"new-product-wrapper swiper-wrapper\">";

var strFooter="</div>"+
"<div class=\"swiper-buttons\">"+
"<div class=\"swiper-button-next\"></div>"+
"<div class=\"swiper-button-prev\"></div>"+
"</div>"+
"</div></div></div>";

var videoPlayer="";
for(var i=0;i<lsData.length;i++){
  
  if(lsData[i]["col6"]!==""){
    videoPlayer="<iframe allow=\"accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture\" allowfullscreen width=\"100%\""+
    "src=\""+lsData[i]["col6"]+"\">"+
    "</iframe>";
  }else{
    videoPlayer="<a  href=\"javascript:void(0)\"><video class=\"img-thumbnail hover-image \" controls type=\"video/youtube\" src=\""+appUrtPath+lsData[i]["col4"]+"\"></video></a>";
  }
    strResp+="<div class=\"single-blog swiper-slide\">";
    if(videoPlayer!==""){
      
    strResp+="<div class=\"blog-image\">"+videoPlayer+"</div>";
    }
   
   strResp+= "<div class=\"blog-text\">"+
        "<p class=\"blog-detail-text\"><b>"+lsData[i]["col1"]+"</b></p>"+
    "</div>"+
"</div>";
}
return strHeader+strResp+strFooter;
}
export default Video_Slider;