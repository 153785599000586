import  $ from 'jquery';
import React from 'react';
import UserToken from '../function_lib/userToken';
import {CallMapMethod,AjaxCallMethod,GetImgCertUser} from '../function_lib/common_lib';
import {CallCMSMapMethod} from '../function_lib/common_cms';
import Front_Header from './front_header';
import Footer from './footer';
import Cart_Canvas from '../sample_comp/cart_canvas';
import Product_Listing from '../sample_comp/product_listing';
import AOS from 'aos';
/* components */
import Top_Banner from '../sample_comp/top_banner';
import Features_Slider  from '../sample_comp/features_slider';
import Home_Banner from '../sample_comp/home_banner';
import Article_Blogs from '../sample_comp/article_blogs';
import Left_Img_Txt_Desc from '../sample_comp/left_img_txt_desc';
import Single_Img_Msg from '../sample_comp/single_img_msg';
import Txt_Description from '../sample_comp/txt_description';
/*Image */
import loginPng from '../assets/images/icons/login.png';

import './login.css';

let loginUser="";
let userObject="";
let clsObject="";
let loc="";
let products="";
let logInObj="";
class Login extends React.Component{
    
  setClickFor(idName){
    if(idName=="nav_contactotp"){
      document.getElementById("nav_login").classList.remove("active");
      document.getElementById("nav_regsi").classList.remove("active");

      document.getElementById("lg1").classList.remove("active");
      document.getElementById("lg2").classList.remove("active");
      document.getElementById("lg3").classList.remove("active");
      
      document.getElementById("lg4").classList.add("active");
    }else if(idName==="nav_otp"){
      document.getElementById("nav_login").classList.remove("active");
      document.getElementById("nav_regsi").classList.add("active");

      document.getElementById("lg2").classList.remove("active");
      document.getElementById("lg1").classList.remove("active");
      document.getElementById("lg4").classList.remove("active");
      document.getElementById("lg3").classList.add("active");
    }else if(idName==="nav_regsi"){
        document.getElementById("nav_login").classList.remove("active");
        document.getElementById("nav_regsi").classList.add("active");
        document.getElementById("lg2").classList.add("active");
        document.getElementById("lg1").classList.remove("active");
        document.getElementById("lg3").classList.remove("active");
        document.getElementById("lg4").classList.remove("active");
        }else{
        document.getElementById("nav_login").classList.add("active");
        document.getElementById("nav_regsi").classList.remove("active");
        document.getElementById("lg2").classList.remove("active");
        document.getElementById("lg1").classList.add("active");
        document.getElementById("lg3").classList.remove("active");
        document.getElementById("lg4").classList.remove("active");
      }
    
    $("#nav_regsi").click();
  }
    constructor(props){
        super(props);
        loc=props.location["pathname"].substr(1);
        if(localStorage.getItem("userDS")!=null){
          logInObj=JSON.parse(localStorage.getItem("userDS"));
        }

        if(localStorage.getItem("userData")!=null){
          //alert(localStorage.getItem("userData"));
          UserToken.setUserObject(localStorage.getItem("userData"));
          userObject= UserToken.getUserObject();
          if(userObject){
            //loginUser=userObject[0].person_name;
          }
          
          //window.location.href="./Dashboard";
        }
      }
      componentDidMount(){
          clsObject=this;
        var pgMap=[];
        pgMap.push({name:"curl_type",value:"getPgData"});
        pgMap.push({name:"res_func",value:"callBackpgData"});
        pgMap.push({name:"loc",value:loc});
       // mapCat.push({name:"gb_token",value:userObject[0].app_user_id});
        //mapCat.push({name:"emp_id",value:userObject[0].id});
        pgMap["res_func"]="callBackpgData";
        pgMap["curl_type"]="getPgData";
        CallCMSMapMethod(clsObject,pgMap);
        AjaxCallMethod(this,"okSuccCNOT");
      }
      okSuccLogn(data,datares){
        if(data==="not_validated"){
          $("#getRegLogn").html(datares);
        }else{
 //alert(data);
 if(data!="0" && data!=="" && data!=="not_validated"){
  $("#getRegLogn").html("Login Successfully..");
 localStorage.setItem('userDS',data);
 window.location.href="/home";
}else{
  $("#getRegLogn").html("Invalid UserName or Password !");
}
    }
  }
    okSuccOTP(data,datares){
      if(data==="not_validated"){
        $("#getRegLognOTP").html(datares);
        return;
      }
     
       if(data!=="0" && data!==""){
          $("#getRegLognOTP").html("Verify Successfully..");
         localStorage.setItem('userDS',data);
         window.location.href="/home";
       }else{
        $("#getRegLognOTP").html("Invalid OTP");
       }
    }
      okSuccCNOT(data,datares){
        //alert(data);
        if(data==="not_validated"){
          $("#getRegLogn").html(datares);
          return;
        }
        if(data!="0" && data!==""){
          clsObject.setClickFor("nav_otp");
          let useOTP=JSON.parse(data);
          $("#refotp_id").val(useOTP[0]["id"]);
          $("#refotp_contact_id").val(useOTP[0]["id"]);
          $(".opt_resp_num").html(datares);
        //  $("#getRegResp").html("Login Successfully..");
        //  localStorage.setItem('userDS',data);
        //  window.location.href="/home";
        }else{
          //clsObject.setClickFor("nav_otp");
          $("#getRegLognReg").html("Invalid UserName or Password !");
        }
    }
      callBackpgData(data,msg){
        //alert("testing of2 "+data);
        //$("#pgComp").html(data);
        var listObj= JSON.parse("{\"access\":"+data+"}");
        //alert("successfully parse");
        //var mainHt=Cart_Canvas();
        var mainHt="";
        if(loc==="home"){
           // mainHt+=Video_Banner_Comp();
        }
        var injextI=0;
        var injCondition=false;
      
        for(var _dlist=0;_dlist<50;_dlist++){
         
            if(listObj.access[_dlist]){
                if(listObj.access[_dlist].top_banner){
                   // alert("I am in conditio");
                   injCondition=true;
                   mainHt+=Top_Banner(listObj.access[_dlist].top_banner);
                }
                else if(listObj.access[_dlist].home_banner){
                   // alert("i am in cond..");
                   injCondition=true;
                    mainHt+=Home_Banner(listObj.access[_dlist].home_banner);
                }else if(listObj.access[_dlist].dual_text_card){
                    injCondition=true;
                    //var dbs=render(){return(new Dual_Banner(listObj.access[_dlist].dual_text_card)};
                   // mainHt+=Dual_Banner(listObj.access[_dlist].dual_text_card);
                }else if(listObj.access[_dlist].single_img_text){
                    injCondition=true;
                    mainHt+=Single_Img_Msg(listObj.access[_dlist].single_img_text);
                }else if(listObj.access[_dlist].img_text_desc_img_left){
                    injCondition=true;
                    mainHt+=Left_Img_Txt_Desc(listObj.access[_dlist].img_text_desc_img_left);
                }else if(listObj.access[_dlist].text_description){
                    injCondition=true;
                    //alert("i am in text");
                    //alert(Txt_Description(listObj.access[_dlist].text_description));
                    mainHt+=Txt_Description(listObj.access[_dlist].text_description);
                }else if(listObj.access[_dlist].list_slider){
                    injCondition=true;
                    mainHt+=Article_Blogs(listObj.access[_dlist].list_slider);
                }
                if(injCondition){
                    injextI++;
                }
                if(injextI==1){
                    //alert("check in condi == "+loc)
                    if(loc==="home"){
                        mainHt+=Features_Slider();
                        //alert(products);
                        mainHt+=products;
                    }
               }
            }
    
          
            //$("#pgComp").innerHtml=mainHt;
        }
        if(listObj.access.length==0){
            mainHt+=products;
        }
        //alert(mainHt);
        //mainHt+=Product_Hori_Slider();
        $("#pgComp").html(mainHt);
        AOS.init();
        AOS.init({
          once: true
        });
        const script = document.createElement("script");
        script.src = "/main.js";
        script.async = true;
        //script.onload = () => clsObject.scriptLoaded();
      
        document.body.appendChild(script);
          //this.forceUpdateHandler();
      
    }
    render(){
        return(<div>
            <Front_Header/>
            <div id="pgComp">
          </div>
           <div className="bg-gogreen login-register-area">
<div className="container">
   <div className="row border-box-2 loginform login-register-tab-list nav">
   <div className="header">
           <img id="logo" src={loginPng} alt="" />
           <p className="formname login-register-tab-list nav"><a id="nav_login" className="text-white active" data-bs-toggle="tab" href="#lg1">
           Sign In
                   </a>
                   /<a id="nav_regsi" className="text-white" data-bs-toggle="tab" href="#lg2">
                   Sign Up
                   </a>
                     </p>
           <p className="formtxt">Sign in to access your Orders, Wishlist and Offers</p>
           
           </div>
           <div className="fdiv tab-content">
           <div id="lg4" className="tab-pane">
             <form className="formarea">
             <input type="hidden" name="curl_type" id="curl_type" value="registerWithOTP" />  
                <input type="hidden" name="m_type" id="m_type" value="registerWithOTP" />
                <input type="hidden" name="res_func" id="res_func" value="okSuccCNOT" />
                <p className="opt_resp_num"></p>
                <input  type="number" name="p1_contact" id="p1_contact" placeholder='Contact No.'  />
                <input type="hidden" name="ref_id" id="refotp_contact_id" />
                <div id="getRegLognOTP" style={{color:"red"}}></div>
                <button className="btn-primary _btnSetColor" type="button"><span>Verify Now</span></button>
             </form>
           </div>

           <div id="lg3" className="tab-pane">
             <form className="formarea">
             <input type="hidden" name="curl_type" id="curl_type" value="logotp" />  
                <input type="hidden" name="m_type" id="m_type" value="logotp" />
                <input type="hidden" name="res_func" id="res_func" value="okSuccOTP" />
                <p className="opt_resp_num"></p>
                <input  type="number" name="p1_otp" id="p1_otp"  />
                <input type="hidden" name="ref_id" id="refotp_id" />
                <div id="getRegLognOTP" style={{color:"red"}}></div>
                <div className="col-md-6 text-right">
                            <div className="payment-link">
                            <p className="copy-text"><a className='linkclass' onClick={()=>this.setClickFor("nav_contactotp")} href="javascript:void(0)">Resend OTP</a></p>
                              
                            </div>
                        </div>
                        <div className="col-md-6 text-left">
                            {/* <p className="copy-text"><a className='linkclass' onClick={()=>this.setClickFor("nav_contactotp")} href="javascript:void(0)">Forget Password.</a></p> */}
                        </div>
                <button className="btn-primary _btnSetColor" type="button"><span>Verify Now</span></button>
             </form>
           </div>
           <div id="lg1" className="tab-pane active">
               <form className="formarea">
               <input type="hidden" name="curl_type" id="curl_type" value="logint" />  
                <input type="hidden" name="m_type" id="m_type" value="logint" />
                <input type="hidden" name="res_func" id="res_func" value="okSuccLogn" />
                                    <input type="text" name="p1" id="p1" placeholder="Contact No/Email" />
                                    <input type="password" name="p2" id="p2" placeholder="Password" />
               <div id="getRegLogn" style={{color:"red"}}></div><br></br>
               <div className="row flex-sm-row-reverse">
                        <div className="col-md-6 text-right">
                            <div className="payment-link">
                            <p className="copy-text"><a className='linkclass' onClick={()=>this.setClickFor("nav_contactotp")} href="javascript:void(0)">Login with OTP</a></p>
                              
                            </div>
                        </div>
                        <div className="col-md-6 text-left">
                            <p className="copy-text"><a className='linkclass' onClick={()=>this.setClickFor("nav_contactotp")} href="javascript:void(0)">Forget Password.</a></p>
                        </div>
                    
                      
                    </div>
               <button className="btn-primary _btnSetColor" type="button"><span>Login</span></button>
               <p className="bottomtxt"><a onClick={()=>this.setClickFor("nav_regsi")} href="javascript:void(0)">Not have login ! Click here to register</a></p>
           </form>
           </div>
           <div id="lg2" className="tab-pane">
               <form className="formarea">
               <input type="hidden" name="curl_type" id="curl_type" value="registration" />  
               <input type="hidden" name="m_type" id="m_type" value="registration" />
               <input type="hidden" name="res_func" id="res_func" value="okSuccCNOT" />
                               <input className="required" autoComplete="off" type="text" name="first_name" id="first_name" placeholder="First Name" />
                               <input className="required" autoComplete="off" type="text" name="last_name" id="last_name" placeholder="Last Name" />
                               <input className="required" autoComplete="off" type="text" name="contact_no" id="contact_no" placeholder="Contact No" />
                                   
                                   <input autoComplete="off" type="text" name="email_id" id="email_id" placeholder="Email" />
                                   
                                   <input className="required" type="password" name="password" id="password" placeholder="Password" />
                                   <input className="required" name="text" placeholder="ReEnter Password" type="text" />
               <div id="getRegLognReg" style={{color:"red"}}></div>
              
               <p className="bottomtxt"> <input type="checkbox" id="vehicle1" name="vehicle1" value="Bike" />
<label for="vehicle1">By continuing, you agree to our<a href="#" className="linkclass"> Terms and Conditions</a></label></p>
                                       <button className="btn-primary _btnSetColor" type="button"><span>Sign Up</span></button>
           </form>
           </div>
       </div>
      
   </div>
</div>
</div>
<Footer/>
       </div>);
    }
}
export default Login;