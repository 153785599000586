import $ from 'jquery';
import React from 'react';
import QueryString from 'query-string';
import UserToken from '../function_lib/userToken';
import {CallMapMethod,RespBkMthdMapp,AjaxCallMethod,GetImgCertUser,btnSetColor_CBack} from '../function_lib/common_lib';
import {getRoleDetails,CallCMSMapMethod} from '../function_lib/common_cms';
import Product_Listing from '../sample_comp/product_listing';
import Front_Header from '../pages/front_header';
import Footer from '../pages/footer';
import Top_Banner from '../sample_comp/top_banner';
import Top_Specified from '../pages/top_specified';
import Home_Product from '../pages/home_product';
import Product_Slider from '../sample_comp/product_slider';
import Features_Slider  from '../sample_comp/features_slider';
import Feature_Web_List from '../sample_comp/feature_web_list';
import Article_Listing from '../sample_comp/article_listing';
import Home_Banner from '../sample_comp/home_banner';
import Article_Blogs from '../sample_comp/article_blogs';
import Left_Img_Txt_Desc from '../sample_comp/left_img_txt_desc';
import Single_Img_Msg from '../sample_comp/single_img_msg';
import Txt_Description from '../sample_comp/txt_description';
import ImageGallery from '../sample_comp/image_gallery';
import Video_Slider from '../sample_comp/video_slider';
import Collapse_Pan from '../sample_comp/collapse_pan';

import Comp_Blog_Land from '../sample_comp/comp_blog_land';
import Account_Opt_Link from './account_opt_link';

import { Player } from 'video-react';
// import AOS from 'aos';
import LogoImg from '../assets/images/my_logo.jpg';
import titleVid from '../assets/videos/imp_water.mp4';

//import titleVid from '../assets/images/backg.gif';
import '../pages/index.css';
//import './blog_landing.css';
/* Media */
import logoImg from '../assets/images/logo/logo.png';

import ban1 from '../assets/images/slider-image/02.jpg';
import ban2 from '../assets/images/slider-image/02.jpg';

import pImg1 from '../assets/images/product-image/AsthmaticaOil.png'
import Left_Menu_Option from '../sample_comp/left_menu_option';
import Blogs from '../sample_comp/blogs';
import Time_Line from '../sample_comp/time_line';

import '../sample_comp/why_us.css';


let loginUser="";
let userObject="";
let clsObject="";
let loc="";
let products="";
let rightBarLists;
let blogData="";
let rf="0";
let queries;
class My_Profile extends React.Component{
    forceUpdateHandler(){
        this.forceUpdate();
      };

    constructor(props){
        super(props);
        this.forceUpdateHandler = this.forceUpdateHandler.bind(this);
        this.state={stAccountInfo:[]};
        this.multiselectRef = React.createRef();
        loc=props.location["pathname"].substr(1);
        if(props !=null){
            queries = QueryString.parse(this.props.location.search);
                  if(queries.rf!=null){
                    rf=queries.rf;
                  }
          }
        //alert(loc);
        if(localStorage.getItem("userDS")!=null){
            userObject=JSON.parse(localStorage.getItem("userDS"));
        }
        
      }
      logout(){
        localStorage.clear();
        window.location.href="./";
      }
changeProfileDetails(){
    btnSetColor_CBack(clsObject,"frmChgProf","changeProfileSets",function(data,msg){
        //alert(data);
        $("#getRegProfReg").html(msg);
        if(data===-10 || data==="-10"){
            $("#getRegProfReg").html("Enter New and Old password");
        }
        if(data==="001"){
            alert("Profile Updated Successfully!");
            $("#getRegProfReg").html("Profile Updated Successfully");
            //clsObject.logout();
        }
    })
}            
changePassword(){
    btnSetColor_CBack(clsObject,"frmChgPwd","changePassword",function(data,msg){
        //alert(data);
        $("#getRegLognReg").html(msg);
        if(data===-10 || data==="-10"){
            $("#getRegLognReg").html("Enter New and Old password");
        }
        if(data==="001"){
            alert("Password changed successfully, Confirm to Logout and Login Again!");
            clsObject.logout();
        }
    })
}      
componentDidMount(){
   // alert(userObject[0].id);
  clsObject=this;
  var mapCat=[];
  mapCat.push({name:"curl_type",value:"getProfile"});
  mapCat.push({name:"res_func",value:"callBackpgData"});
  mapCat.push({name:"gb_token",value:"5e7hmtL7R0GU7zEBVhJZmrKx8Qb4xy-G_ihGPxqUtrGt5qMHlOaQK8ba1r-o7qKuAChRCIL0_Pilz1gStWW2Mg"});
  mapCat.push({name:"p1",value:"new arrivals"});
  mapCat.push({name:"p2",value:"0"});
  mapCat.push({name:"app_user_id",value:userObject[0].id});
  mapCat["res_func"]="callBackpgData";
  mapCat["curl_type"]="getProfile";
  CallMapMethod(clsObject,mapCat);
  AjaxCallMethod(clsObject,"okSuccCNOT");

  var mapCat=[];
  if(loc===""){
      loc="home";
  }
  //alert(loc);
  //loc="blogs_article"
  
  
}
callBackpgData(data,msg){
   // var listObj= JSON.parse("{\"access\":"+data+"}");
    if(data!=="0"){
        let lsAccInfo=JSON.parse(data);
        this.setState({stAccountInfo:lsAccInfo});
    }


    // AOS.init();
    // AOS.init({
    //   once: true
    // });
    const script = document.createElement("script");
    script.src = "/main.js";
    script.async = true;
    //script.onload = () => clsObject.scriptLoaded();
  
    document.body.appendChild(script);
      //this.forceUpdateHandler();
    // if(ifImgGallery){
    //     $('.gallery-item').magnificPopup({
    //         type: 'image',
    //         gallery: {
    //             enabled: true
    //         }
    //     });
    // }
  
}




    render(){
        return(<div>
          <Front_Header/>

          <div className="offcanvas-overlay"></div>
          <div className="blog-grid pb-100px pt-10px main-blog-page">
        <div className="container">
            <div className="row">
            
<Account_Opt_Link />

         <div id="pgComp" className="col-lg-12 col-md-12">
            
<div class="checkout-area pt-10 pb-10">
        <div class="container">
            <div class="row">
            <div class="ml-auto mr-auto col-lg-9">
                    <div class="checkout-wrapper">
                    <div id="faq" class="panel-group">
                        <div class="panel panel-default single-my-account">
                                <div class="panel-heading my-account-title">
                                    <h3 class="panel-title"><span>1 .</span> <a data-bs-toggle="collapse" class="collapsed" aria-expanded="true" href="#my-account-1">My account information </a>
                                    </h3>
                                </div>
                                <div id="my-account-1" class="panel-collapse collapse show" data-bs-parent="#faq">
                                    <div class="panel-body">
                                        <div class="myaccount-info-wrapper">
                                            <div class="account-info-wrapper">
                                                {/* <h4>My Account Information</h4> */}
                                                <h5>Your Personal Details</h5>
                                            </div>
                                            {this.state.stAccountInfo.map((item,index)=>{
                                                return(
                                                    <form id="frmChgProf" name="frmChgProf">
                                                        <input type="hidden" id="m_type" name="m_type" value={"changeProfileSets"} />
                                            <input type="hidden" id="res_func" name="res_func" value="ntokenff"/>
                                                <input type="hidden" id="app_user_id" name="app_user_id" value={userObject[0].id} />
                                                <div class="row">
                                                    <div class="col-lg-12 col-md-12">
                                                    <div class="billing-info">
                                                        <label>Email Address</label>
                                                        <input type="email" readonly={"true"} class="form-control" defaultValue={item["email_id"]} />
                                                    </div>
                                                </div>
                                                <div class="col-lg-6 col-md-6">
                                                    <div class="billing-info">
                                                        <label>First Name</label>
                                                        <input type="text" id="e_first_name" name="e_first_name" autocomplete="off" class="form-control" defaultValue={item["first_name"]} />
                                                    </div>
                                                </div>
                                                <div class="col-lg-6 col-md-6">
                                                    <div class="billing-info">
                                                        <label>Last Name</label>
                                                        <input type="text" id="e_last_name" name="e_last_name" autocomplete="off" class="form-control" defaultValue={item["last_name"]} />
                                                    </div>
                                                </div>
                                                
                                                <div class="col-lg-6 col-md-6">
                                                    <div class="billing-info">
                                                        <label>Contact No.</label>
                                                        <input type="text" id="e_contact_no" name="e_contact_no" autocomplete="off"  class="form-control" defaultValue={item["contact_no"]} />
                                                    </div>
                                                </div>
                                                
                                            </div>
                                            <div class="billing-back-btn">
                                            <div id="getRegProfReg" style={{color:"red"}}></div>
                                            <div class="billing-back-btn">
                                                {/* <div class="billing-back">
                                                    <a href="#"><i class="icon-arrow-up-circle"></i> back</a>
                                                </div> */}
                                                <div class="billing-btn">
                                                    <a style={{"color":"#fff"}} href="javascript:void(0)" className="white float-right btn-sm btn-lg btn-primary btn-hover-dark mt-5" onClick={()=>this.changeProfileDetails()}>Save Changes</a>
                                                    {/* <button className="float-right btn-sm btn-lg btn-primary_two btn-hover-dark mt-5" type="button" onClick={()=>this.changeProfileDetails()}>Submit</button> */}
                                                </div>
                                            </div>
                                            </div>
                                            </form>)
                                            })}
                                            
                                           
                                        </div>
                                    </div>
                                </div>
                            </div>
                         </div>

                         {/* end of first */}
                         <div class="panel panel-default single-my-account">
                                <div class="panel-heading my-account-title">
                                    <h3 class="panel-title"><span>2 .</span> <a data-bs-toggle="collapse" class="collapsed" aria-expanded="false" href="#my-account-2">Change your password </a></h3>
                                </div>
                                <div id="my-account-2" class="panel-collapse collapse" data-bs-parent="#faq">
                                    <div class="panel-body">
                                        <div class="myaccount-info-wrapper">
                                            <div class="account-info-wrapper">
                                                {/* <h4>Change Password</h4> */}
                                                <h5>Your Password</h5>
                                            </div>
                                            <form id="frmChgPwd" name="frmChgPwd">
                                            <div class="row">
                                            <input type="hidden" id="m_type" name="m_type" value={"changePassword"} />
                                            <input type="hidden" id="res_func" name="res_func" value="ntoken"/>
                                                <input type="hidden" id="app_user_id" name="app_user_id" value={userObject[0].id} />
                                            <div class="col-lg-12 col-md-12">
                                                    <div class="billing-info">

                                                        <label>Old Password</label>
                                                        <input className="required" type="password" id="old_password" name="old_password" />
                                                    </div>
                                                </div>
                                                <div class="col-lg-12 col-md-12">
                                                    <div class="billing-info">
                                                        <label>New Password</label>
                                                        <input className="required" type="password" id="new_password" name="new_password" />
                                                    </div>
                                                </div>
                                                <div class="col-lg-12 col-md-12">
                                                    <div class="billing-info">
                                                        <label>Password Confirm</label>
                                                        <input className="required" type="password" id="conf_password" name="conf_password" />
                                                    </div>
                                                </div>
                                            </div>
                                            <div id="getRegLognReg" style={{color:"red"}}></div>
                                            <div class="billing-back-btn">
                                                {/* <div class="billing-back">
                                                    <a href="#"><i class="icon-arrow-up-circle"></i> back</a>
                                                </div> */}
                                                <div class="billing-btn">
                                                <a style={{"color":"#fff"}} href="javascript:void(0)" className="white float-right btn-sm btn-lg btn-primary btn-hover-dark mt-5" onClick={()=>this.changePassword()}>Save Changes</a>
                                                    {/* <button type="button" onClick={()=>this.changePassword()}>Submit</button> */}
                                                </div>
                                            </div>
                                            </form>
                                        </div>
                                    
                                    </div>
                                </div>
                            </div>
                            {/* end of session 2 */}

                    </div>        
            </div>
            </div>
        </div>
</div>       
         </div>
        
</div>
</div>
</div>
          <Footer/>
          <div className="modal fade" id="exampleModal" tabindex="-1" role="dialog">
          <div className="modal-dialog" role="document">
              <div className="modal-content">
                  <div className="modal-header">
                      <button type="button" className="close" data-bs-dismiss="modal" aria-label="Close"><span
                              aria-hidden="true">x</span></button>
                  </div>
                  <div className="modal-body">
                      <div className="row">
                          <div className="col-md-5 col-sm-12 col-xs-12 mb-lm-30px mb-sm-30px">
                              
                              <div className="swiper-container gallery-top mb-4">
                                  <div className="swiper-wrapper">
                                      <div className="swiper-slide">
                                          <img className="img-responsive m-auto" src="assets/images/product-image/1.jpg" alt=""/>
                                      </div>
                                      <div className="swiper-slide">
                                          <img className="img-responsive m-auto" src="assets/images/product-image/2.jpg" alt=""/>
                                      </div>
                                      <div className="swiper-slide">
                                          <img className="img-responsive m-auto" src="assets/images/product-image/3.jpg" alt=""/>
                                      </div>
                                      <div className="swiper-slide">
                                          <img className="img-responsive m-auto" src="assets/images/product-image/4.jpg" alt=""/>
                                      </div>
                                      <div className="swiper-slide">
                                          <img className="img-responsive m-auto" src="assets/images/product-image/5.jpg" alt=""/>
                                      </div>
                                  </div>
                              </div>
                              <div className="swiper-container gallery-thumbs slider-nav-style-1 small-nav">
                                  <div className="swiper-wrapper">
                                      <div className="swiper-slide">
                                          <img className="img-responsive m-auto" src="assets/images/product-image/1.jpg" alt=""/>
                                      </div>
                                      <div className="swiper-slide">
                                          <img className="img-responsive m-auto" src="assets/images/product-image/2.jpg" alt=""/>
                                      </div>
                                      <div className="swiper-slide">
                                          <img className="img-responsive m-auto" src="assets/images/product-image/3.jpg" alt=""/>
                                      </div>
                                      <div className="swiper-slide">
                                          <img className="img-responsive m-auto" src="assets/images/product-image/4.jpg" alt=""/>
                                      </div>
                                      <div className="swiper-slide">
                                          <img className="img-responsive m-auto" src="assets/images/product-image/5.jpg" alt="" />
                                      </div>
                                  </div>
                                 
                                  <div className="swiper-buttons">
                                      <div className="swiper-button-next"></div>
                                      <div className="swiper-button-prev"></div>
                                  </div>
                              </div>
                          </div>
                          <div className="col-md-7 col-sm-12 col-xs-12">
                              <div className="product-details-content quickview-content">
                                  <h2>Originals Kaval Windbr</h2>
                                  <p className="reference">Reference:<span> demo_17</span></p>
                                  <div className="pro-details-rating-wrap">
                                      <div className="rating-product">
                                          <i className="ion-android-star"></i>
                                          <i className="ion-android-star"></i>
                                          <i className="ion-android-star"></i>
                                          <i className="ion-android-star"></i>
                                          <i className="ion-android-star"></i>
                                      </div>
                                      <span className="read-review"><a className="reviews" href="#">Read reviews (1)</a></span>
                                  </div>
                                  <div className="pricing-meta">
                                      <ul>
                                          <li className="old-price not-cut">$18.90</li>
                                      </ul>
                                  </div>
                                  <p className="quickview-para">Lorem ipsum dolor sit amet, consectetur adipisic elit eiusm tempor incidid ut labore et dolore magna aliqua. Ut enim ad minim venialo quis nostrud exercitation ullamco</p>
                                  <div className="pro-details-size-color">
                                      <div className="pro-details-color-wrap">
                                          <span>Color</span>
                                          <div className="pro-details-color-content">
                                              <ul>
                                                  <li className="blue"></li>
                                                  <li className="maroon active"></li>
                                              </ul>
                                          </div>
                                      </div>
                                  </div>
                                  <div className="pro-details-quality">
                                      <div className="cart-plus-minus">
                                          <input className="cart-plus-minus-box" type="text" name="qtybutton" value="1" />
                                      </div>
                                      <div className="pro-details-cart btn-hover">
                                          <button className="add-cart btn btn-primary btn-hover-primary ml-4"> Add To
                                              Cart</button>
                                      </div>
                                  </div>
                                  <div className="pro-details-wish-com">
                                      <div className="pro-details-wishlist">
                                          <a href="wishlist.html"><i className="ion-android-favorite-outline"></i>Add to
                                              wishlist</a>
                                      </div>
                                      <div className="pro-details-compare">
                                          <a href="compare.html"><i className="ion-ios-shuffle-strong"></i>Add to compare</a>
                                      </div>
                                  </div>
                                  <div className="pro-details-social-info">
                                      <span>Share</span>
                                      <div className="social-info">
                                          <ul>
                                              <li>
                                                  <a href="#"><i className="ion-social-facebook"></i></a>
                                              </li>
                                              <li>
                                                  <a href="#"><i className="ion-social-twitter"></i></a>
                                              </li>
                                              <li>
                                                  <a href="#"><i className="ion-social-google"></i></a>
                                              </li>
                                              <li>
                                                  <a href="#"><i className="ion-social-instagram"></i></a>
                                              </li>
                                          </ul>
                                      </div>
                                  </div>
                              </div>
                          </div>
                      </div>
                  </div>
              </div>
          </div>
          </div>
          </div>
          
          );
    }
}
export default My_Profile