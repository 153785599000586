import React from 'react';
import {appGetUrlPath} from '../function_lib/common_cms';
export function Blogs(lsData){
    //alert("I am in blogs");
    var appPath=appGetUrlPath();
    var strHeader="<div class=\"blog-grid pt-10px main-blog-page single-blog-page\">"+
    "<div class=\"container\">"+
        "<div class=\"row\">"+
            "<div class=\"col-lg-12 order-lg-last col-md-12 order-md-first\">"+
                "<div class=\"blog-posts \">";
    var strFooter="</div></div></div></div></div>";
    
    var strResp="";
    for(var i=0;i<lsData.length;i++){
        let chkImg="";
        if(lsData[i]["col4"]!==""){
            chkImg= "<div class=\"blog-post-media\">"+
            "<div class=\"blog-image single-blog\">"+
                "<img src=\""+appPath+lsData[i]["col4"]+"\" alt=\"blog\" />"+
            "</div>"+
        "</div>";
        }
        strResp+="<div class=\"single-blog-post blog-grid-post\">"+
                chkImg+
                "<div class=\"blog-post-content-inner mt-6\">"+
                "<h4 class=\"blog-title\"><a href=\"blog-single-left-sidebar.html\">"+lsData[i]["col1"]+"</a></h4>"+
                "<p data-aos=\"fade-up\">"+lsData[i]["col2"]+"</p>"+
                "<p data-aos=\"fade-up\">"+lsData[i]["col3"]+"</p>"+
                "</div></div>";
    }
  strResp=strHeader+strResp+strFooter;
  return strResp;
}
export default Blogs;