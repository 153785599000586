import $ from 'jquery';
import {React,useEffect} from 'react';
import { useLocation } from 'react-router-dom';
import {useStripe, useElements, PaymentElement} from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import {Elements} from '@stripe/react-stripe-js';
import QueryString from 'query-string';
import {getAppUrl} from '../function_lib/common_lib';

const stripePromise = loadStripe('pk_test_51NbZDaA09pCeyE6pfiAejrdxwEuob41YB4jdaXGZkurfkAAlaLrh2Wg5GKdbebDuojgdgz3VkhPB9doNfZGtTvkT00c4d2sN3i');
let params="";

const DisableBackForward = () => {
  useEffect(() => {
    const disableBackForward = () => {
      window.history.pushState(null, null, window.location.pathname);
      window.addEventListener('popstate', function(event) {
        window.history.pushState(null, null, window.location.pathname);
      });
    };

    disableBackForward();

    return () => {
      window.removeEventListener('popstate', disableBackForward);
    };
  }, []);

  
};
/*
const handleBackButton = (event) => {
  event.preventDefault();
  alert('Back button clicked!');
  console.log('User clicked back button');
  // Additional logic can be added here
};
*/


export function InitPayment() {
  console.log("check event11");
  //window.addEventListener('popstate', handleBackButton);
  window.history.pushState({}, null, null);
  //DisableBackForward(); //comment existing event


  useEffect(() => {
    const handleBackButton = (event) => {
      event.preventDefault(); // Prevents the default behavior of the back button
      //alert('Back button clicked!');
      window.location.href="../invoice_receipt?rf="+params.token;
      // You can add additional logic here if needed
    };

    window.onpopstate = handleBackButton;

    return () => {
      // Clean up the event listener when the component unmounts
      window.onpopstate = null;
    };
  }, []); // Empty dependency array ensures this effect runs only once



    let locationParam=useLocation();
    if(params===""){
      params= QueryString.parse(locationParam.search);
    }
    
    //alert(JSON.stringify(params));
    //alert(params.secret);
    if(typeof params.secret==="undefined"){
        return <div>Invalid call</div>
    }
    if(typeof params.token==="undefined"){
        return <div>Invalid call</div>
    }
  const options = {
    // passing the client secret obtained from the server
    //clientSecret: 'pi_3NuwzqA09pCeyE6p00KEphIS_secret_YeifgRMv3cpjz0dsy9oxUnQdp'
    clientSecret: params.secret
  };

  return (
    <Elements stripe={stripePromise} options={options}>
      <CheckoutForm />
    </Elements>
  );
}


export const CheckoutForm = () => {
  const stripe = useStripe();
  const elements = useElements();

  const handleSubmit = async (event) => {
    // We don't want to let default form submission happen here,
    // which would refresh the page.
    event.preventDefault();

    if (!stripe || !elements) {
      // Stripe.js hasn't yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return;
    }

    const result = await stripe.confirmPayment({
      
      //`Elements` instance that was used to create the Payment Element
      elements,
      confirmParams: {
        return_url: 'https://test.fastrcdirect.com/_redirect/'+params.token
      },
    });

    if (result.error) {
      
      // Show error to your customer (for example, payment details incomplete)
      //console.log(result.error.message);
      //document.getElementById("error_message").innerHtml= result.error.message;
      $("#error_message").html(result.error.message);
      //alert("nn"+JSON.stringify(result.error.message));
    } else {
      // Your customer will be redirected to your `return_url`. For some payment
      // methods like iDEAL, your customer will be redirected to an intermediate
      // site first to authorize the payment, then redirected to the `return_url`.
    }
  };
 
  return (
    <div className="checkout-area pt-100px pb-100px">
            <div className="container">
            
    <form onSubmit={handleSubmit}>
      <PaymentElement />
      <div style={{color:"red"}} id="error_message" className="error_message"></div>
      <button className="btn btn-primary btn-outline-dark current-btn mt-2" disabled={!stripe}>Pay ${parseFloat(atob(params.tm))/100}</button>
    </form>
    </div>
    </div>
  )
};

export default InitPayment;